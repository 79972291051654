import { Injectable } from '@angular/core';
import { LocalStorageSaveOptions } from '../..';

@Injectable({
  providedIn: 'root',
})
export class RequestCachService {
  constructor() {}

  save(options: LocalStorageSaveOptions) {
    // set default values for optionals
    options.expirationMins = options.expirationMins || 0;
    // set expiration date in miliseconds
    const expirationMs =
      options.expirationMins !== 0 ? options.expirationMins * 60 * 1000 : 0;
    const record = {
      value:
        typeof options.data === 'string'
          ? options.data
          : JSON.stringify(options.data),
      expiration:
        expirationMs !== 0 ? new Date().getTime() + expirationMs : null,
      hasExpiration: expirationMs !== 0,
    };

    sessionStorage.setItem(options.key, JSON.stringify(record));
  }

  load(key: string) {
    // get cached data from sessionStorage
    const item = sessionStorage.getItem(key);
    if (item != '' && item != null) {
      const record = JSON.parse(item);
      const now = new Date().getTime();
      // Expired data will be return null
      if (!record || (record.hasExpiration && record.expiration <= now)) {
        return null;
      } else {
        return JSON.parse(record.value);
      }
    }
    return null;
  }
  removeKey(key: string) {
    sessionStorage.removeItem(key);
  }
  cleanLocalStorage() {
    sessionStorage.clear();
  }
}
