import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { DineMessageService } from '..';
import { CheckOutService } from '../../service/check-out.service';


@Injectable({
  providedIn: 'root'
})
export class LocationGuard implements CanActivate {

  constructor(
    private router: Router,
    private checkoutService: CheckOutService,
    private dineMessageService: DineMessageService
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let locationId = next.params.locationId;

    if (!locationId) {
      this.router.navigate(['/department']);
      return false;
    }

    // let resp = await this.checkoutService.isLocationSetup(locationId).toPromise();

    // if (resp.result) {
    //   return true;
    // }

    this.dineMessageService.showError('Selected Location is not activated for Ordering')
    
    this.router.navigate(['/department']);
    return false;
  }
}
