export interface IAdyenSessionModel {
  amount: {
    currency: string;
    value: number;
  };
  countryCode: string;
  expiresAt: Date;
  id: string;
  merchantAccount: string;
  reference: string;
  returnUrl: string;
  sessionData: string;
  shopperLocale: string;
  splitCardFundingSources: boolean;
  threeDSAuthenticationOnly: boolean;
}

export interface IAdyenPaymentMethods {
  brands: string[];
  name: string;
  type: string;
  brand: string;
}

export interface IPaymentRequestModel {
  message?: any;
  purchasedSuccess: boolean;
  authenticateUri?: any;
  downloadUri?: any;
  omiseId?: any;
  invoiceNo?: any;
  paymentId: number;
  stripeId?: any;
  requestId: number;
  wheelTicket?: any;
  adyenSession: IAdyenSessionResponse;
}

export interface IPaymentResponse {
  message: string;
  purchasedSuccess: boolean;
  authenticateUri: string;
  downloadUri: string;
  omiseId: string;
  invoiceNo: string;
  paymentId: number;
  stripeId: string;
  requestId: string;
  errorMessage: string;
}


export interface IAdyenSessionResponse {
  amount: {
    currency: string;
    value: number;
  };
  countryCode: string;
  expiresAt: Date;
  id: string;
  merchantAccount: string;
  reference: string;
  returnUrl: string;
  sessionData: string;
  shopperLocale: string;
  splitCardFundingSources: boolean;
  threeDSAuthenticationOnly: boolean;
}

export interface IPendingPaymentRequests {
  id: number;
  tenantId: number;
  paymentRef: string;
  paymentOption: string;
  amount: number;
  paymentComplete: boolean;
}

export interface IItemPriceModel {
  price: string;
  value: number;
  isSelected: boolean;
}

export interface ICustomerTopupInput {
  cardId: number | undefined;
  tenantId: number | undefined;
  amount: number | undefined;
  userId: number | undefined;
  paymentType: string | undefined;
  omiseToken: string | undefined;
  stripeToken: string | undefined;
  paymentTypeId: number | undefined;
  paymentId: number | undefined;
  locationId: number | undefined;
  callbackUrl: string;
  omisePayNow: boolean;
  currency: string;
  enable3DSecure: boolean;
}

export class ITranscationPaymentInput {
  tenantId: number | undefined;
  userId: number | undefined;
  skipCount: number | undefined;
  maxResultCount: number | undefined;
}


export interface IAdyenSessionResult {
  orderId: string;
  session: IAdyenSessionModel
}
