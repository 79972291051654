import { SpinnerActionTypes, SpinnerActions } from '../actions/spinner.actions';

export interface ISpinnerState {
  count: number;
}

const initialState: ISpinnerState = {
  count: 0,
};

export function spinnerReducer(state = initialState, action: SpinnerActions): ISpinnerState {
  switch  (action.type) {
    case SpinnerActionTypes.ShowSpinner: {
      return {
        ...state,
        count: state.count + 1
      };
    }
    case SpinnerActionTypes.HideSpinner: {
      return {
        ...state,
        count: 0,
      };
    }

    default:
      return state;
  }
}
