import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import { JwtService } from './jwt.service';
import { catchError, finalize } from 'rxjs/operators';
import { DineMessageService } from '../../service/message-top-down.service';
import { UserService } from '../../service';
import { environment } from '../../../../environments/environment';
import { AppConsts } from '../constant/constant';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  baseUrl = environment.remoteServiceBaseUrl;
  requestCounter = 0;
  isValidRequest: Boolean = true;

  constructor(
    public auth: JwtService,
    private userService: UserService,
    private dineMessageService: DineMessageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var leng = window.location.pathname.split('/');
    var tenantNameslist = AppConsts.getWaysJSON.Code;
    if (
      AppConsts.getWaysJSON.Code &&
      leng.some((r) => tenantNameslist.includes(r))
    ) {
      var tenantName = leng.filter((x) => tenantNameslist.includes(x));
      let tenantObject = AppConsts.getWaysJSON;
      if (tenantObject) {
        this.isValidRequest = true;
        environment.selectedTenantName = tenantObject.Code;
        environment.remoteServiceBaseUrl = tenantObject.TenantUrl;
        environment.tenantId = tenantObject.TenantId;
      } else {
        this.dineMessageService?.showError(
          'Invalid Url. Please enter correct Url'
        );
        this.isValidRequest = false;
      }
    } else if (leng[1]) {
      // sessionStorage.setItem(CONSTANT.TENANT_NAME,leng[1]);
      // this.isValidRequest = false;
    } else {
      this.dineMessageService?.showError(
        'Invalid Url. Please enter correct Url'
      );
      this.isValidRequest = false;
    }

    if (!this.isValidRequest) {
      return EMPTY;
    } else {
      this.beginRequest();
      if (
        !request.url.includes('www') &&
        !request.url.includes('googleapis') &&
        !request.url.includes('http') &&
        !request.url.includes('onemap')
      ) {
        request = this.addAuthHeader(request);
        let requestUrl = '';
        let storedUrl = sessionStorage.getItem('url');
        if (storedUrl) {
          this.baseUrl = storedUrl;
        }
        requestUrl = `${this.baseUrl ? this.baseUrl : ''}${request.url}`;

        request = request.clone({
          url: requestUrl,
          body: { ...request.body, gateway: AppConsts.getWaysJSON.TenantName },
        });
      }
    }

    return next.handle(request).pipe(
      finalize(() => {
        this.endRequest();
      }),
      catchError((error) => {
        return this.handleResponseError(error, request, next);
      })
    );
  }

  beginRequest() {
    this.requestCounter = Math.max(this.requestCounter, 0) + 1;
  }

  endRequest() {
    this.requestCounter = Math.max(this.requestCounter, 1) - 1;
  }

  addAuthHeader(request: any) {
    let tokenValue = this.userService.userToken;

    let setHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      'Abp-CustomerId': this.userService.customerId || '',
      Authorization: '',
    };

    const authenHeader = request.headers.get('Authorization');

    if (authenHeader && !authenHeader.includes('null')) {
      setHeaders['Authorization'] = authenHeader;
    } else if (tokenValue) {
      setHeaders['Authorization'] = `Bearer ${tokenValue}`;
    }

    return request.clone({
      setHeaders: setHeaders,
    });
  }

  handleResponseError(
    error: HttpErrorResponse,
    request?: any,
    next?: any
  ): Observable<HttpEvent<any>> {
    switch (error.status) {
      case 401:
        // return this.auth.refreshToken(CONSTANT.META_DATA_TOKEN_KEY).pipe(
        //   switchMap(() => {
        //     request = this.addAuthHeader(request);
        //     return next.handle(request);
        //   }),
        //   catchError((e) => {
        //     if (e.status !== 401) {
        //       return this.handleResponseError(e);
        //     } else {
        //       this.auth.logOut();
        //     }
        //   })
        // );
        return throwError(error);

      // // Access denied error
      // case 403:
      //   break;

      // // Server error
      // case 500:
      //   break;

      // // Maintenance error
      // case 503:
      //   break;

      default:
        let message = error?.error?.error?.message;
        this.dineMessageService.showError(message ?? error?.message);
        return throwError(error);
    }
  }
}
