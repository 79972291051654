import { Store, Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, tap, finalize, takeUntil, take } from 'rxjs/operators';
import { isDefined } from '../../common/utils/helper-fns';
import { HideSpinnerAction, ShowSpinnerAction } from '../../store/actions/spinner.actions';

export class DataLoader {
  private actionsInProgress = new Set<string>();

  constructor(protected store: Store<any>) {
  }

  protected loader<E>(action: Action, selector: any, unsubscriber$: Observable<any>, forceLoad?: boolean, listen?: boolean): Observable<E> {
    let isSpinnerShown: any | null = null;
    const actionId = JSON.stringify(action);

    const load = () => {
      if (!this.actionsInProgress.has(actionId)) {
        this.actionsInProgress.add(actionId);
        this.store.dispatch(action);
      }
    }
    const hideSpinner = () => {
      if (isSpinnerShown) {
        this.store.dispatch(new HideSpinnerAction());
        isSpinnerShown = false;
      }
    };
    const showSpinner = () => {
      if (isSpinnerShown === null) {
        this.store.dispatch(new ShowSpinnerAction());
        isSpinnerShown = true;
      }
    };

    return this.store.select(selector).pipe(
      tap(val => {
        if (!val || forceLoad) {
          showSpinner();
          load();
        }
      }),
      filter(isDefined),
      listen ? tap(hideSpinner) : take(1),
      finalize(() => {
        hideSpinner();
        this.actionsInProgress.delete(actionId);
      }),
      takeUntil(unsubscriber$)
    );
  }

}
