import { ITimeSlot } from "../departement/time-slot.model";

export interface ITableReservationModel {
  id: number;
  confirmationId: string;
  wheelLocationId: number;
  wheelDepartmentId: number;
  onlineCustomerId: number;
  reservationDate: string;
  adults: any;
  children: any;
  timeSlotId: number;
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  phoneNumber: string;
  tableId: number;
  tableName: string;
  notes: string;
  tenantId: number;
  timeSlot: ITimeSlot;
  shareableUrl: string;
}

export interface IOnlineCustomer {
  customerId: number;
  customerName: string;
  tenantId: number;
}
