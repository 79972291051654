import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// NGRX
import { act, Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { DineMessageService, UserService } from '../../service';
import { CookieService } from 'ngx-cookie';
import { LoggedInAction, LoginActionRequested, logoutAction, SignupActionRequested, SignupActionResponse, UserLoaded } from '../actions/auth.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
import { apiResponseError } from '../actions/common.actions';
import { ILogingResponseModel, ISignupResponseModel } from '../../model';
import { CONSTANT } from '../../common';
import { ApiResponse } from '../../model/api-response.model';

@Injectable()
export class AuthEffect {
  //@Effect({ dispatch: false })

  @Effect()
  LoginActionRequested$ = this.actions$.pipe(
    ofType(LoginActionRequested),
    switchMap(
      (action) => {
        return this.userService.login(action.body).pipe(
          map(
            (res: ILogingResponseModel) => {
              if (res.success) {
                sessionStorage.setItem(CONSTANT.AUTH, JSON.stringify(res));
                return LoggedInAction({
                  user: res as ILogingResponseModel
                });
              } else {
                this.messageService.showError(res.error.message);
                return apiResponseError(res.error as any);
              }

            }),
          catchError(
            error => of(apiResponseError(error))),
        );
      }));

  @Effect()
  SignupActionRequested$ = this.actions$.pipe(
    ofType(SignupActionRequested),
    switchMap(
      (action) => {
        return this.userService.signup(action.body).pipe(
          map(
            (res: any) => {
              if (res.success && res.result.userId > 0) {
                this.messageService.showSuccess('You have successfuly registered. Now you can login with provided password.');
                this.router.navigateByUrl(`/${action.body.tenantName}/sign-in`);
                return SignupActionResponse({
                  user: res as ISignupResponseModel
                });

              } else {
                this.messageService.showError(res.error.message);
                return apiResponseError(res.error);
              }

            }),
          catchError(
            error => of(apiResponseError(error))),
        );
      }));

  @Effect()
  UserRequested$ = this.actions$.pipe(
    ofType(LoggedInAction),
    switchMap(
      (action) => {
        return this.userService.getUserInfo(action.user.userId, action.user.tenantId).pipe(
          map(
            (res: any) => {
              if (res.success) {
                return UserLoaded({
                  user: res.result
                });

              } else {
                this.messageService.showError(res.error.message);
                return apiResponseError(res.error);
              }

            }),
          catchError(
            error => of(apiResponseError(error))),
        );
      }));


  @Effect()
  LogoutActionRequested$ = this.actions$.pipe(
    ofType(logoutAction),
    switchMap(
      (action) => {
        return this.userService.logout().pipe(
          map(
            (res: ApiResponse) => {
              if (res) {
                sessionStorage.removeItem(CONSTANT.AUTH);

                return apiResponseError(res.error as any);
              } else {
                return apiResponseError(res.error as any);
              }

            }),
          catchError(
            error => of(apiResponseError(error))),
        );
      }));


  @Effect()
  init$: Observable<Action> = defer(() => {
    let observableResult = of({ type: 'NO_ACTION' });
    let user;
    try {
      if (sessionStorage.getItem(CONSTANT.AUTH)) {
        user = JSON.parse(sessionStorage.getItem(CONSTANT.AUTH));
        if (user)
          observableResult = of(
            LoggedInAction({ user })
          );
      }

      return observableResult;
    } catch (err) {
      return observableResult;
    }
  });

  constructor(
    private actions$: Actions,
    private router: Router,
    private userService: UserService,
    private messageService: DineMessageService,
    private cookieStorage: CookieService,
    private store: Store<any>,

  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        //this.returnUrl = event.url;
      }
    });
  }
}
