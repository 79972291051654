import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AppSession, OrderSetting } from '../constant/constant';


@Injectable({
  providedIn: 'root'
})
export class MainGuard implements CanActivate {

  constructor(
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      if (OrderSetting.signUp && this.router.url.indexOf('sign') === -1) {
        const userId = AppSession?.user?.userId;
        if (!userId && OrderSetting.signUp) {
          this.router.navigate(['/sign-in'], { queryParams: { redirectUrl: this.router.url } });
          return false;
        }
      }
      return true;
    
  }
}
