export interface IAddress {
  address1: string;
  address2: string;
  address3: string;
  postalCode: string;
  cityId: number;
  stateId: number;
  countryId: number;
  stateName: string;
  cityName: string;
  countryName: string;
  tenantId: number;
  isDeleted: true;
  deleterUserId: number;
  deletionTime: Date;
  lastModificationTime: Date;
  lastModifierUserId: number;
  creationTime: Date;
  creatorUserId: number;
  id: number;
}
