import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { HttpOptions, TokenType } from './http-options.model';
import { RequestCachService } from '../cache-client/request-cache.service';
import { CONSTANT } from '../../constant/constant';


export enum Verbs {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
}
@Injectable({
  providedIn: 'root',
})
export class HttpClientService {
  constructor(
    private http: HttpClient,
    private _cacheService: RequestCachService
  ) {}

  get<T>(options: HttpOptions): Observable<T> {
    return this.httpCall(Verbs.GET, options);
  }

  delete<T>(options: HttpOptions): Observable<T> {
    return this.httpCall(Verbs.DELETE, options);
  }

  put<T>(options: HttpOptions): Observable<T> {
    return this.httpCall(Verbs.PUT, options);
  }

  post<T>(options: HttpOptions): Observable<T> {
    return this.httpCall(Verbs.POST, options);
  }

  private httpCall<T>(vebs: Verbs, options: HttpOptions): Observable<T> {

    // set up default value
    options.body = options.body || null;
    options.cacheMins = options.specificCacheKey === CONSTANT.TENANT_AVAILABLE_KEY.toString() ? options.cacheMins : 0;
    options.specificCacheKey = options.specificCacheKey === CONSTANT.TENANT_AVAILABLE_KEY.toString() ? options.specificCacheKey : '';
    options.forceReload = options.forceReload || false;

    if (!options.forceReload) {
      if (Number(options.cacheMins) > 0) {
        // get data from cache
        let saveKey = options.url;
        if (options.specificCacheKey) {
          saveKey = options.specificCacheKey;
        }
        const data = this._cacheService.load(saveKey);
        // return data from cache
        if (data != null) {
          return of<T>(data);
        }
      }
    }

    return this.http
      .request<T>(vebs, options.url, {
        body: options.body,
      })
      .pipe(
        switchMap((response: any) => {
          if (Number(options.cacheMins) > 0) {
            // data will be cached
            let saveKey = options.url;
            if (options.specificCacheKey) {
              saveKey = options.specificCacheKey;
            }

            this._cacheService.save({
              key: saveKey,
              data: response,
              expirationMins: options.cacheMins,
            });
          }
          return of<T>(response);
        })
      );
  }
}
