import { HttpOptions, TokenType } from '../../core/common/config/http-client/http-options.model';
import { HttpClientService } from '../../core/common/config/http-client/http-client.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ILocation, IPageResult, IPageResultList, ISimpleLocation } from '../model';
import { prepareGetUrl } from '../common/helpers/url-helper';


@Injectable({
  providedIn: 'root',
})
export class LocationService {
  $isOpenLocation = new BehaviorSubject<boolean>(false);
  deliveryLocation = '';
  constructor(private http: HttpClientService) {}

  openLocationPopUp() {
    this.$isOpenLocation.next(true);
  }

  getLocationList(
    filter: string | null | undefined,
    organizationId: number | undefined,
    sorting: string | null | undefined,
    maxResultCount: number | undefined,
    skipCount: number | undefined
  ): Observable<IPageResultList<Location>> {
    let url_ = '/api/services/app/Location/GetList?';
    url_ = prepareGetUrl(
      url_,
      filter,
      organizationId,
      sorting,
      maxResultCount,
      skipCount
    );

    const options: HttpOptions = {
      url: url_,
      cacheMins: 5,
      typeToken: TokenType.META_TOKEN,
    };

    return this.http.get(options);
  }

  getSimpleLocations(
    filter: string | null | undefined,
    organizationId: number | undefined,
    sorting: string | null | undefined,
    maxResultCount: number | undefined,
    skipCount: number | undefined
  ): Observable<IPageResultList<ISimpleLocation>> {
    let url_ = '/api/services/app/Location/GetSimpleLocations?';
    url_ = prepareGetUrl(
      url_,
      filter,
      organizationId,
      sorting,
      maxResultCount,
      skipCount
    );

    const options: HttpOptions = {
      url: url_,
      cacheMins: 0,
      typeToken: TokenType.META_TOKEN,
    };

    return this.http.get<IPageResultList<ISimpleLocation>>(options);
  }

  getLocationById(id: number | undefined): Observable<IPageResult<ILocation>> {
    let url_ = '/api/services/app/Location/Get?';
    if (id === null) throw new Error("The parameter 'id' cannot be null.");
    else if (id !== undefined)
      url_ += 'id=' + encodeURIComponent('' + id) + '&';
    url_ = url_.replace(/[?&]$/, '');

    const options: HttpOptions = {
      url: url_,
      cacheMins: 5,
      typeToken: TokenType.META_TOKEN,
    };

    return this.http.get<IPageResult<ILocation>>(options);
  }
}
