import { CustomMenuItemData, IMenuItem } from './custom-menu-item.model';
export interface ICustomCategoryMenu {
  categoryId: number;
  categoryName: string;
  items: IMenuItem[];
}

export interface ICustomMenuItem {
  categoryId: number;
  categoryName: string;
  items: CustomMenuItemData[];
}
