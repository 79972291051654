import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, Subject, throwError } from 'rxjs';
import { IPageResult } from '../model';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store';
import { ApiResponse } from '../model/api-response.model';
import { catchError, tap } from 'rxjs/operators';
import { CONSTANT } from '../common';
import { Guid } from 'guid-typescript';
import {
  HideSpinnerAction,
  ShowSpinnerAction,
} from '../store/actions/spinner.actions';

@Injectable({
  providedIn: 'root',
})
export class WheelOrderService {
  private prodCount: number = 0;
  public prodCountCountChange: Subject<number> = new Subject();

  updateCount(count: number = 0): void {
    this.prodCount = count;
    this.prodCountCountChange.next(this.prodCount);
  }

  removePromoCode() {
    sessionStorage.removeItem(CONSTANT.PROMO_CODE);
  }

  constructor(private store: Store<IAppState>, private http: HttpClient) {}

  getWheelOrdersByUser(userId: number): Observable<ApiResponse> {
    return this.http
      .get<ApiResponse>(
        `/api/services/app/WheelOrder/GetAlllWheelOrders?customerId=${userId}`
      )
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getPageToBack(
    pageId: number,
    wheelDepartermentId: number,
    isGenerateQRCode: boolean
  ): Observable<ApiResponse> {
    if (isGenerateQRCode == undefined) {
      isGenerateQRCode = true;
    }
    let url_ = '/api/services/app/WheelOrder/GetPageGoToBack';
    url_ += `?currentPage=${encodeURIComponent(
      '' + pageId
    )}&wheelDepatermentId=${encodeURIComponent(
      '' + wheelDepartermentId
    )}&isGenerateQRCode=${isGenerateQRCode}`;
    url_ = url_.replace(/[?&]$/, '');

    return this.http.get<ApiResponse>(url_).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getCart(
    wheelLocationId: number,
    wheelDepartmentId: number,
    sessionId: string,
    IncludeSubTotal?: boolean,
    point?: any,
    voucherCode?: any,
    includeStopChange?: boolean
  ): Observable<ApiResponse> {
    const body = {
      tenantId: environment.tenantId,
      wheelLocationId: wheelLocationId,
      wheelDepartmentId: wheelDepartmentId,
      sessionId: sessionId,
      IncludeSubTotal: IncludeSubTotal,
      selectedAddress: point,
      voucherId: voucherCode?.voucherId,
      groupId: voucherCode?.groupId,
      includeStopChange: includeStopChange,
    };
    return this.http
      .post<ApiResponse>(`/api/services/app/FrontCart/ApiCartItems`, body)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  generateKey(item: any, isComboItem: boolean = false): string {
    var rand = Guid.create().toString().substring(0, 5);

    let keys = [`menuId-${item.screenMenuItemId}-quantity-${item.quantity}`];

    if (item.portion) {
      keys.push('size-' + item.portion?.id);
    }

    if (isComboItem) {
      keys.push('quantity-' + (item.quantity ?? 1));
    }

    if (item.tags) {
      item.tags.forEach((tag: any) => {
        keys.push('tag-' + tag.id + '-quantity-' + (tag.quantity ?? 1));
      });
    }

    // return keys.join('-');

    return `menuId-${item.screenMenuItemId}-` + Guid.create().toString();
  }

  generateComboKey(item: any): string {
    let keys = [this.generateKey(item)];

    if (item.subItems) {
      item.subItems.forEach((subItem: any) => {
        keys.push(this.generateKey(subItem, true));
      });
    }

    return keys.join('-');
  }

  addOrderItemToCart(item: any, isCombo: boolean = false): Observable<any> {
    this.removePromoCode();
    let input = {
      id: item.id,
      tenantId: environment.tenantId,
      sessionId: environment.sessionId,
      key: isCombo ? this.generateComboKey(item) : this.generateKey(item),
      screenMenuItemId: item.screenMenuItemId,
      isCombo: item.isCombo,
      quantity: item.quantity,
      tags: item.tags,
      portion: item.portion,
      note: item.note,
      subItems: item.subItems,
      menuItemId: item.menuItemId,
      itemName: item.itemName,
      oldKey: item.oldKey,
      onlineCustomerId: item.onlineCustomerId,
      wheelLocationId: item.locationId ?? item.wheelLocationId,
    };

    return this.http
      .post<IPageResult<number>>(
        '/api/services/app/FrontCart/ApiAddItemToCart',
        input
      )
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  addOrderItemsToCart(
    items: Array<any>,
    isCombo: boolean = false
  ): Observable<any> {
    this.removePromoCode();
    var inputArr = Array<any>();
    items.forEach((item) => {
      inputArr.push({
        id: item.id,
        tenantId: environment.tenantId,
        sessionId: environment.sessionId,
        key: isCombo ? this.generateComboKey(item) : this.generateKey(item),
        screenMenuItemId: item.screenMenuItemId,
        quantity: item.quantity,
        tags: item.tags,
        portion: item.portion,
        note: item.note,
        subItems: item.subItems,
        menuItemId: item.menuItemId,
        itemName: item.itemName,
        oldKey: item.oldKey,
        onlineCustomerId: item.onlineCustomerId,
        wheelLocationId: item.locationId ?? item.wheelLocationId,
      });
    });

    return this.http
      .post<IPageResult<number>>(
        '/api/services/app/FrontCart/ApiAddItemsToCart',
        { data: inputArr }
      )
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  updateOrderItemToCart(item: any) {
    this.removePromoCode();
    return this.http
      .post<ApiResponse>(`/api/services/app/FrontCart/ApiAddItemToCart`, {
        ...item,
        tenantId: environment.tenantId,
        sessionId: environment.sessionId,
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  removeOrderItemFromCart(item: any) {
    this.removePromoCode();
    let content = {
      screenMenuItemId: item.screenMenuItemId,
      Key: item.key,
      tenantId: environment.tenantId,
      sessionId: environment.sessionId,
      wheelLocationId: item.locationId ?? item.wheelLocationId,
    };
    const spinnerId = Guid.create().toString();
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));

    return this.http
      .post<ApiResponse>(
        `/api/services/app/FrontCart/RemoveItemFromCart`,
        content
      )
      .pipe(
        tap(() =>
          this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))
        ),
        catchError((err) => {
          this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
          return throwError(err);
        })
      );
  }

  removeSoldItemsFromCart(item: any) {
    this.removePromoCode();
    let content = {
      screenMenuItemId: item.screenMenuItemId,
      Key: item.key,
      tenantId: environment.tenantId,
      sessionId: environment.sessionId,
      wheelLocationId: item.locationId ?? item.wheelLocationId,
    };

    return this.http
      .post<ApiResponse>(
        `/api/services/app/FrontCart/RemoveSoloutItemsFromCart`,
        content
      )
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getUserOrderHistory(sessionId: string, tenantId: number) {
    const spinnerId = Guid.create().toString();
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));

    return this.http
      .post<ApiResponse>(`/api/services/app/FrontTicket/GetUserOrderHistory`, {
        sessionId,
        tenantId,
      })
      .pipe(
        tap(() =>
          this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))
        ),
        catchError((err) => {
          this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
          return throwError(err);
        })
      );
  }

  addCustomerOptInInfo(info: any) {
    return this.http
      .post<ApiResponse>(
        `/api/services/app/FrontWheel/ApiAddCustomerOptInInfo`,
        { ...info }
      )
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  cancelOrder(ticketId: number, tenantId: number) {
    const spinnerId = Guid.create().toString();
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));

    return this.http
      .post<ApiResponse>(`/api/services/app/FrontSync/ApiCancelTicket`, {
        id: ticketId,
        tenantId,
      })
      .pipe(
        tap(() =>
          this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))
        ),
        catchError((err) => {
          this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
          return throwError(err);
        })
      );
  }
}
