import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Guid } from 'guid-typescript';
import { ApiResponse } from '../model/api-response.model';
import {
  HideSpinnerAction,
  ShowSpinnerAction,
} from '../store/actions/spinner.actions';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store';
import { catchError, tap } from 'rxjs/operators';
import {
  IWheelScreenItemsRequest,
  IWheelScreenItemsSearchRequest,
} from '../model';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WheelMenuService {
  constructor(private store: Store<IAppState>, private http: HttpClient) {}

  loadWheelScreenCategories(
    id: number,
    tenantId: number
  ): Observable<ApiResponse> {
    return this.http
      .post<ApiResponse>(`/api/services/app/frontMenu/ApiScreenCategories`, {
        id,
        tenantId,
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  loadWheelScreenSubCategories(
    id: number,
    tenantId: number,
    locationId: number
  ): Observable<ApiResponse> {
    return this.http
      .post<ApiResponse>(`/api/services/app/frontMenu/ApiSubCategories`, {
        id,
        tenantId,
        locationId,
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  loadWheelScreenItems(
    model: IWheelScreenItemsRequest
  ): Observable<ApiResponse> {
    return this.http
      .post<ApiResponse>(`/api/services/app/frontMenu/ApiScreenItems`, model)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  loadlScreenItemsByCategory(
    model: IWheelScreenItemsRequest
  ): Observable<ApiResponse> {
    return this.http
      .post<ApiResponse>(
        `/api/services/app/frontMenu/ApiScreenItemsByCategory`,
        model
      )
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  loadlApiScreenMenuData(
    model: IWheelScreenItemsRequest
  ): Observable<ApiResponse> {
    return this.http
      .post<ApiResponse>(`/api/services/app/frontMenu/ApiScreenMenuData`, model)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  loadWheelScreenItemsSearch(
    model: IWheelScreenItemsSearchRequest
  ): Observable<ApiResponse> {
    return this.http
      .post<ApiResponse>(
        `/api/services/app/frontMenu/ApiScreenItemsSearch`,
        model
      )
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  loadWheelScreenItemDetail(
    id: number,
    tenantId: number,
    locationId: number,
    wheelDepartment: number,
    dayOfOrder: string
  ): Observable<ApiResponse> {
    if (dayOfOrder?.length > 10) {
      dayOfOrder = dayOfOrder.slice(0, 10);
    }
    return this.http
      .post<ApiResponse>(`/api/services/app/frontMenu/ApiScreenItemDetail`, {
        id,
        tenantId,
        locationId,
        wheelDepartment,
        dayOfOrder,
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  loadWheelScreenMenu(
    tenantId: number,
    locationId: number,
    departmentId: number
  ): Observable<ApiResponse> {
    return this.http
      .post<ApiResponse>(`/api/services/app/frontMenu/ApiWheelScreenMenu`, {
        tenantId,
        locationId,
        departmentId,
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getTicketByInvoice(
    invoiceNo: string,
    locationId: number
  ): Observable<ApiResponse> {
    const spinnerId = Guid.create().toString();

    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));
    let url = '/api/services/app/frontSync/ApiGetTicketByInvoice';
    const body = {
      tenantId: environment.tenantId,
      sessionId: environment.sessionId,
      locationId: locationId,
      invoiceNumber: invoiceNo,
    };

    return this.http.post<ApiResponse>(url, body).pipe(
      tap(() =>
        this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))
      ),
      catchError((err) => {
        this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
        return throwError(err);
      })
    );
  }
}
