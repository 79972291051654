import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { IWheelScreenCategory, IWheelScreenItem, IWheelScreenItemsRequest } from '../../model';
import { ICartItems } from '../../model/cart.model';
import { cartItemsRequested } from '../../store/actions/common.actions';
import { wheelCategoriesRequested, wheelScreenMenuItemsRequested, wheelSubCategoriesRequested } from '../../store/actions/menu.actions';
import { selectCartItems } from '../../store/selectors/common.selectors';
import { selectCategories, selectMenuItems, selectSubCategories } from '../../store/selectors/menu.selectors';
import { DataLoader } from './data-loader';


@Injectable({
  providedIn: 'root'
})
export class DataLoaderService extends DataLoader {
  constructor(protected store: Store<any>) {
    super(store);
  }

  loadCategories(
    id: number,
    tenantId: number,
    unsubscriber: Observable<any>,
  ): Observable<Array<IWheelScreenCategory>> {
    return this.loader(
      wheelCategoriesRequested({ body: { id: id, tenantId: tenantId } }),
      selectCategories,
      unsubscriber,
      false
    );
  }

  loadSubCategories(
    id: number,
    tenantId: number,
    locationId: number,
    unsubscriber: Observable<any>,
  ): Observable<Array<string>> {
    return this.loader(
      wheelSubCategoriesRequested({ body: { id: id, tenantId: tenantId, locationId: locationId } }),
      selectSubCategories(id),
      unsubscriber,
      false
    );
  }

  loadScreenMenuItems(model: IWheelScreenItemsRequest,
    unsubscriber: Observable<any>,
  ): Observable<Array<IWheelScreenItem>> {
    return this.loader(
      wheelScreenMenuItemsRequested({ body: model }),
      selectMenuItems(model.pageNo),
      unsubscriber,
      true
    );
  }

  loadCartItems(
    locationId: number,
    departmentId: number,
    sessionId: string,
    includeSubTotal: boolean = false,
    selectedAddress: any,
    unsubscriber: Observable<any>,
  ): Observable<ICartItems> {
    return this.loader(
      cartItemsRequested({ body: { locationId: locationId, departmentId: departmentId, sessionId: sessionId, includeSubTotal: includeSubTotal, selectedAddress:selectedAddress } }),
      selectCartItems,
      unsubscriber,
      true
    );
  }
}
