import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiResponse } from '../model/api-response.model';
import { IAppState } from '../../store';
import { Store } from '@ngrx/store';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ITableReservationModel } from '../model';
import { Guid } from 'guid-typescript';
import {
  HideSpinnerAction,
  ShowSpinnerAction,
} from '../store/actions/spinner.actions';

@Injectable({
  providedIn: 'root',
})
export class WheelLocationsService {
  constructor(private http: HttpClient, private store: Store<IAppState>) {}

  getWheelLocationsByTenant(): Observable<ApiResponse> {
    return this.http
      .get<ApiResponse>(
        `/api/services/app/WheelSetups/GetWheelSetupLocationsByTenant`
      )
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getWheelLocationForEdit(locationId: number): Observable<ApiResponse> {
    return this.http
      .get<ApiResponse>(
        `/api/services/app/WheelLocations/GetWheelLocationForEdit?Id=${locationId}`
      )
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getAllWheelTableForTableDropdown(
    wheelLocationId: number,
    wheelDepartmentId: number
  ): Observable<ApiResponse> {
    let url_ = `/api/services/app/WheelTable/GetAllWheelTableForTableDropdown?wheelLocationId=${wheelLocationId}&wheelDepartmentId=${wheelDepartmentId}`;

    return this.http.get<ApiResponse>(url_).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getWheelDepartments(
    tenantId: number,
    locationId: number
  ): Observable<ApiResponse> {
    let url_ = '/api/services/app/frontWheelDepartment/ApiWheelDepartments';
    return this.http
      .post<ApiResponse>(url_, { tenantId: tenantId, locationId: locationId })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getWheelDepartment(
    id: number,
    tenantId: number,
    locationId: number
  ): Observable<ApiResponse> {
    let url_ = '/api/services/app/frontWheelDepartment/ApiWheelDepartment';
    return this.http
      .post<ApiResponse>(url_, {
        id: id,
        tenantId: tenantId,
        locationId: locationId,
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getTimeSlots(
    dateStr: string,
    locationId: number,
    departmentId: number
  ): Observable<ApiResponse> {
    let url_ = '/api/services/app/frontWheelDepartment/ApiTimeSlots';

    return this.http
      .post<ApiResponse>(url_, {
        dateStr: dateStr,
        tenantId: environment.tenantId,
        locationId: locationId,
        departmentId: departmentId,
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  verifyTableviaOTP(
    tenantId: number,
    tableId: number,
    otp: number
  ): Observable<any> {
    let url_ = `/api/services/app/frontWheel/VerifyTableOtp`;
    const content_ = { tenantId: tenantId, table: tableId, otp: otp };

    return this.http.post<ApiResponse>(url_, content_).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getTableDetail(tenantId: number, tableId: number): Observable<any> {
    let url_ = `/api/services/app/frontWheel/GetTableDetail`;
    const content_ = { tenantId: tenantId, table: tableId };

    return this.http.post<ApiResponse>(url_, content_).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  saveTableReserveData(resData: ITableReservationModel): Observable<any> {
    let url_ = `/api/services/app/frontWheel/ReserveTable`;

    return this.http.post<ApiResponse>(url_, resData).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  confirmTableReservation(resData: any): Observable<any> {
    let url_ = `/api/services/app/frontWheel/ReserveTableAfterCapture`;

    const spinnerId = Guid.create().toString();
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));
    return this.http.post<ApiResponse>(url_, resData).pipe(
      tap(() =>
        this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))
      ),
      catchError((err) => {
        this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
        return throwError(err);
      })
    );
  }

  createReservationPaymentRequest(
    resData: ITableReservationModel
  ): Observable<any> {
    let url_ = `/api/services/app/frontWheel/ApiReservationPaymentRequest`;

    const spinnerId = Guid.create().toString();
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));
    return this.http.post<ApiResponse>(url_, resData).pipe(
      tap(() =>
        this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))
      ),
      catchError((err) => {
        this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
        return throwError(err);
      })
    );
  }

  getReservationTableDetail(input: ITableReservationModel): Observable<any> {
    let url_ = `/api/services/app/frontWheel/GetReserveTableDetail`;

    return this.http.post<ApiResponse>(url_, input).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getDetailByConfirmation(
    tenantId: number,
    confirmationId: number
  ): Observable<any> {
    let url_ = `/api/services/app/frontWheel/GetReserveTableDetailByConfirmation`;

    return this.http
      .post<ApiResponse>(url_, {
        tenantId: tenantId,
        confirmationId: confirmationId,
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  shareTableLinkByEmail(input: ITableReservationModel): Observable<any> {
    let url_ = `/api/services/app/frontWheel/ShareTableLinkByEmail`;

    return this.http.post<ApiResponse>(url_, input).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  shareTableLinkByWhatsapp(input: ITableReservationModel): Observable<any> {
    let url_ = `/api/services/app/frontWheel/ShareTableLinkByWhatsApp`;

    return this.http.post<ApiResponse>(url_, input).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  createOnlineCustomer(
    tenantId: number,
    customerName: string
  ): Observable<any> {
    let url_ = `/api/services/app/frontWheel/CreateOnlineCustomer`;

    return this.http
      .post<ApiResponse>(url_, {
        tenantId: tenantId,
        customerName: customerName,
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  checkLocationInDeliveryZones(
    tenantId: number,
    latitude: number,
    longitude: number
  ): Observable<any> {
    let url_ = `/api/services/app/frontCart/checkLocationInDeliveryZones`;

    return this.http
      .post<ApiResponse>(url_, { tenantId, latitude, longitude })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  isLocationOnline(
    tenantId: number,
    locationId: number
  ): Observable<ApiResponse> {
    let url_ = `/api/services/app/frontWheel/IsLocationOnline`;

    return this.http.post<ApiResponse>(url_, { tenantId, locationId }).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}
