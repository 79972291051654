import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuItemService {

  totalPrice = 0;
  $totalPrice = new BehaviorSubject<number>(this.totalPrice);

  constructor() { }

  plusTotalPrice(value:number) {
    this.totalPrice += value;
    this.$totalPrice.next(this.totalPrice);
  }

  minorTotalPrice(value:number) {
    this.totalPrice -= value;
    this.$totalPrice.next(this.totalPrice);
  }
}
