export interface IResultModel<T> {
  totalCount: number;
  items: T[] | undefined;
}

export interface IPageResultList<T> {
  result: IResultModel<T>;
  targetUrl: string;
  success: boolean;
  error: any;
  unAuthorizedRequest: boolean;
}


export interface IPageResult<T> {
  result: T;
  targetUrl: string;
  success: boolean;
  error: any;
  unAuthorizedRequest: boolean;
}
