import { createAction, props } from '@ngrx/store';
import {
  IAdyenPaymentMethods,
  IAdyenSessionResult,
  ICartItemActionModel,
  ICartItemRequestModel,
  IIsTenantAvailableInput,
  ITableOtpRequest,
  ITableOtpResponse,
} from '../../model';
import { ApiResponse } from '../../model/api-response.model';
import { IGatewayData } from '../../model/gateway.model';
import { IWheelSetupResultModel } from '../../model/wheel-setup.model';

export const checkTenantRequested = createAction(
  '[Wheel Setup] check tenant available',
  props<{ body: IIsTenantAvailableInput }>()
);
export const checkTenantLoaded = createAction(
  '[Wheel Setup] check tenant loaded',
  props<{ result: ApiResponse }>()
);

export const currentLoginInfoRequested = createAction(
  '[Wheel Setup] Current Login Info Requested'
);
export const currentLoginInfoLoaded = createAction(
  '[Wheel Setup]  Current Login Info Loaded',
  props<{ result: GetWheelCustomerLoginInformationsOutput }>()
);

export const cartItemsRequested = createAction(
  '[Wheel Setup] cart items requested',
  props<{ body: ICartItemRequestModel }>()
);
export const addItemToCartRequested = createAction(
  '[Wheel Setup] add item to cart',
  props<{ body: { item: any; cartModel: any } }>()
);
export const cartItemsLoaded = createAction(
  '[Wheel Setup] cart items loaded',
  props<{ result: any }>()
);
export const removeItemFromCart = createAction(
  '[Wheel Setup] remvoe item from cart',
  props<{ body: ICartItemActionModel }>()
);
export const removeSoloutItemsFromCart = createAction(
  '[Wheel Setup] remove soldout item from cart',
  props<{ body: ICartItemActionModel }>()
);
export const updateItemFromCart = createAction(
  '[Wheel Setup] update item from cart',
  props<{ body: ICartItemActionModel }>()
);
export const resetCartRequested = createAction(
  '[Wheel Setup] reset cart requested'
);

export const tableDetailRequested = createAction(
  '[Wheel Setup] get table detail available',
  props<{ body: ITableOtpRequest }>()
);
export const tableDetailLoaded = createAction(
  '[Wheel Setup] table detail loaded',
  props<{ result: ITableOtpResponse }>()
);
export const tableClearData = createAction('[Wheel Setup] table clear data');

export const wheelGatewayDataRequested = createAction(
  '[Wheel GatewayData] Wheel GatewayData Requested',
  props<{ tenant: string }>()
);
export const wheelGatewayDataLoaded = createAction(
  '[Wheel GatewayData] Wheel GatewayData Loaded',
  props<{ result: IGatewayData }>()
);

export const wheelSetupRequested = createAction(
  '[Wheel Setup] Wheel Setup Requested',
  props<{ tenant: string }>()
);
export const wheelSetupLoaded = createAction(
  '[Wheel Setup] Wheel Setup Loaded',
  props<{ result: IWheelSetupResultModel }>()
);

export const adyenCheckoutSessionRequested = createAction(
  '[Adyen Checkout Session] Adyen Checkout Session Requested',
  props<{ body: any }>()
);
export const adyenCheckoutSessionLoaded = createAction(
  '[Adyen Checkout Session] Adyen Checkout Session Loaded',
  props<{ result: IAdyenSessionResult }>()
);

export const adyenPaymentMethodsRequested = createAction(
  '[Adyen Payment Methods] Adyen Payment Methods Requested',
  props<{ body: any }>()
);
export const adyenPaymentMethodsLoaded = createAction(
  '[Adyen Payment Methods] Adyen Payment Methods Loaded',
  props<{ result: IAdyenPaymentMethods[] }>()
);

export const apiResponseError = createAction(
  '[Wheel Setup] check tenant available',
  props<{ error: any }>()
);

export const timeOutDialogSate = createAction(
  '[Main Menu] timeout dialog state',
  props<{ timeoutState: boolean }>()
);

export class GetWheelCustomerLoginInformationsOutput {
  name!: string | undefined;
  phoneNumber!: string | undefined;
  emailAddress!: string | undefined;
  customerGuid!: string | undefined;
  userId!: number | undefined;
}
