import { Injectable } from '@angular/core';
import { Resolve, Router, RoutesRecognized } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import { StartUpService } from '../config/start-up/start-up.service';
import { CONSTANT, wheelDataSetups } from '../constant/constant';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})

export class RouteResolverService implements Resolve<any> {
  constructor(private wheelSetupsService: StartUpService, private sessionStorage: LocalStorageService, private router: Router) {
    //this.router.events
    //  .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
    //  .subscribe((events: RoutesRecognized[]) => {
    //    console.log('previous url', events[0].urlAfterRedirects);
    //    console.log('current url', events[1].urlAfterRedirects);
    //  });
  }

  resolve(): Observable<any> {
    var id = Number(sessionStorage.getItem(CONSTANT.TENANT_ID));
    if (wheelDataSetups?.wheelLocations?.length > 0) {
      return of(wheelDataSetups);
    } else {
      return this.wheelSetupsService.getWheelSetups(id);
    }
  }
}
