import { Action, createReducer, on } from "@ngrx/store";
import { ILoggedInUserInfo, ILogingResponseModel } from "../../model";
import { LoggedInAction, logoutAction, UserLoaded } from "../actions/auth.actions";

export interface IAuthState {
  isLoggedIn:boolean,
  user: ILogingResponseModel,
  userInfo: ILoggedInUserInfo
}


export const defaultState: IAuthState = {
  isLoggedIn:false,
  user: undefined,
  userInfo: undefined
};


const reducer = createReducer(
  defaultState,

  on(LoggedInAction,
    (state, action) => ({
      ...state,
      authUser: action.user,
      isLoggedIn: action.user?.userId > 0
    }),
  ),
  on(logoutAction,
    (state, action) => ({
      ...state,
      authUser: undefined,
      userInfo: undefined,
      isLoggedIn: false
    }),
  ),
  on(UserLoaded,
    (state, action) => ({
      ...state,
      userInfo: action.user,
    }),
  ),
)

export function authReducer(state: IAuthState = defaultState, action: Action): IAuthState {
  return reducer(state, action);
}

export const authStateKey = 'authStateKey';
