import { DineMessageService, MenuItemService } from '../common';
import { UserService } from './user.service';
import { CheckOutService } from './check-out.service';
import { LocalCookieService } from './local-cookie.service';
import { LocationService } from './location.service';
import { ScriptLoaderService } from './script-loader.service';
import { WheelLocationsService } from './wheel-locations.service';
import { WheelOrderService } from './wheel-order.service';
import { WheelSetupsService } from './wheel-setups.service';
import { DataLoaderService } from './loaders/data-loader.service';
import { ToastService } from './toast.service';
import { TenantService } from './tenant.service';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { DynamicPagesService } from './dynamic-pages.service';
import { PersistanceService } from './persistance.service';
import { FirebaseService } from './firebase.service';
import { UserWalletService } from './user-wallet.service';

export * from './user.service';
export * from './check-out.service';
export * from './location.service';
export * from './menu-item.service';
export * from './message-top-down.service';
export * from './script-loader.service';
export * from './wheel-locations.service';
export * from './wheel-setups.service';
export * from './local-cookie.service';
export * from './loaders/data-loader.service';
export * from './toast.service';
export * from './tenant.service';
export * from './encrypt-decrypt.service';
export * from './dynamic-pages.service';
export * from './persistance.service';
export * from './firebase.service';
export * from './user-wallet.service';

export const coreServices = [
  UserService,
  CheckOutService,
  LocationService,
  MenuItemService,
  DineMessageService,
  ScriptLoaderService,
  WheelLocationsService,
  WheelOrderService,
  WheelSetupsService,
  LocalCookieService,
  DataLoaderService,
  ToastService,
  TenantService,
  EncryptDecryptService,
  DynamicPagesService,
  PersistanceService,
  FirebaseService,
  UserWalletService
];
