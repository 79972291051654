export interface IWheelScreenCategory {
  id: number;
  name: string;
  image: string;
  sortOrder: number;
  itemsCount: number;
  displayOnly: boolean;
}

export interface IWheelScreenItemsRequest {
  id: number;
  tenantId: number;
  locationId: number;
  wheelDepartment: number;
  categories: Array<number>;
  Search: string;
  SkipCount: number;
  MaxResultCount: number;
  SubCategory: string;
  pageNo: number;
  dateOfDelivery: any;
}

export interface IWheelScreenItemsSearchRequest {
  id: number;
  tenantId: number;
  locationId: number;
  sessionId: number;
  Search: string;
  SkipCount: number;
  MaxResultCount: number;
  dateOfDelivery: any;
  menuCategories: any;
  wheelDepartment: number;
}

export interface IScreenItemsByCategory {
  category: number;
  categoryName: string;
  items: Array<IWheelScreenItem>;
}

export interface IWheelScreenItem {
  screenMenuItem: any;
  quantity: number;
  id: number;
  guid: string;
  name: string;
  image?: string;
  sortOrder: number;
  categoryId: number;
  subCategory?: any;
  isSoldOut: boolean;
  menuItemId: number;
  isCombo: boolean;
  price: number;
  tags?: any;
  videoPath: string;
  description?: string;
  locationId: number;
  departmentId: number;
  reservationId: number;
  tagGroupName: string;
  total: number;
  checked: boolean;
  disabled: boolean;
  maxQuantity: number;
  minQuantity: number;
  key: string;
  displayOnly: boolean;
  autoSelect: boolean;
}

export interface IWheelScreenItemDetail {
  id: number;
  guid: string;
  name: string;
  aliasName: string;
  image?: any;
  sortOrder: number;
  subCategory?: any;
  isSoldOut: boolean;
  menuItemId: number;
  price: number;
  isCombo: boolean;
  portions: IWheelScreenPortion[];
  orderTags: IWheelScreenOrderTag[];
  comboGroups?: any;
  menuItemPortionId: number;
  maximum: number;
  type: number;
  autoSelect: boolean;
  description: string;
  total: number;
  quantity: number;
  note: string;
  tags: string;
}

export interface IWheelScreenOrderTag {
  items: IWheelScreenItem[];
  name: string;
  sortOrder: number;
  minSelectedItems: number;
  maxSelectedItems: number;
  disabled: boolean;
  id: number;
  active: boolean;
  guid: string;
}

export interface IWheelScreenPortion {
  id: number;
  guid: string;
  name: string;
  price: number;
  checked: boolean;
}

export interface IWheelScreenComboDetail {
  isValid: boolean;
  errors: string[];
  total: number;
  id: number;
  name: string;
  aliasName: string;
  image?: any;
  sortOrder: number;
  subCategory?: any;
  isSoldOut: boolean;
  menuItemId: number;
  price: number;
  isCombo: boolean;
  portions: IWheelScreenComboPortion[];
  orderTags: IWheelScreenComboOrderTag[];
  comboGroups: IWheelScreenComboGroup[];
  menuItemPortionId: number;
  maximum: number;
  type: number;
  autoSelect: boolean;
  description?: any;
  quantity: number;
  locationId: number;
  departmentId: number;
  tags: string;
  note: string;
}

export interface IWheelScreenComboGroup {
  total: number;
  id: number;
  guid: string;
  name: string;
  items: IWheelScreenComboItem[];
  sortOrder: number;
  minimum: number;
  maximum: number;
  isValid: boolean;
  checked: boolean;
  disabled: boolean;
}

export interface IWheelScreenComboItem {
  defaultQuantity: number;
  tagGroupName: string;
  quantity: number;
  total: number;
  checked: boolean;
  disabled: true;
  selected: boolean;
  id: number;
  guid: string;
  name: string;
  image?: any;
  sortOrder: number;
  subCategory?: any;
  isSoldOut: boolean;
  menuItemId: number;
  price: number;
  isCombo: boolean;
  portions: IWheelScreenComboPortion[];
  orderTags: IWheelScreenComboOrderTag[];
  comboGroups?: IWheelScreenComboGroup[];
  menuItemPortionId: number;
  maximum: number;
  type: number;
  autoSelect: boolean;
  description: string;
  isValid: boolean;
  expanded: boolean;
}

export interface IWheelScreenComboOrderTag {
  items: IWheelScreenComboItem[];
  name: string;
  autoSelect: boolean;
  sortOrder: number;
  minSelectedItems: number;
  maxSelectedItems: number;
  id: number;
  guid: string;
  expanded: boolean;
}

export interface IWheelScreenComboPortion {
  checked: boolean;
  id: number;
  name: string;
  price: number;
}

export interface IWheelScreenMenu {
  id: number;
  name: string;
  addOn: string;
  active: boolean;
  description: string;
}
