import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { DineMessageService, WheelSetupsService } from '../../../service';
import {
  AppConsts,
  AppSession,
  CONSTANT,
  wheelDataSetups,
} from '../../constant/constant';
import { AUTH_USER } from '../../constant/shared.const';
import { IGatewayData } from '../../../model/gateway.model';
import { Observable } from 'rxjs';
import { cloneDeep, isArray } from 'lodash';
import {
  wheelGatewayDataLoaded,
  wheelSetupLoaded,
} from '../../../store/actions/common.actions';
import { LoggedInAction } from '../../../store/actions/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class StartUpService {
  constructor(
    private _cookieService: CookieService,
    private wheelSetupsService: WheelSetupsService,
    private store: Store<any>
  ) {}

  getGatewayData(code: string) {
    return this.wheelSetupsService.getGatewayInfo(code).pipe(
      tap((res) => {
        this.store.dispatch(wheelGatewayDataLoaded({ result: res }));
        return res;
      })
    );
  }

  getWheelSetups(id: number): Observable<any> {
    return this.wheelSetupsService.getWheelSetups(id).pipe(
      tap((resp) => {
        if (!resp.result) {
          return;
        }
        this.store.dispatch(wheelSetupLoaded({ result: resp.result }));

        const {
          countryCode,
          currency,
          isDisplayHomePage,
          logo,
          pageTitle,
          termsAndConditions,
          welcomeMessage,
          checkoutMessage,
          wheelLocations,
          noProductImage,
          reservationCaptureAmount,
        } = resp.result;
        wheelDataSetups.countryCode = countryCode || 'SG';
        wheelDataSetups.currency = currency || 'SGD';
        wheelDataSetups.isDisplayHomePage = isDisplayHomePage;
        wheelDataSetups.logoCompanyUrl = logo;
        wheelDataSetups.title = pageTitle || 'eDine';
        wheelDataSetups.termsAndConditions = termsAndConditions;
        wheelDataSetups.welcomeMessage = welcomeMessage;
        wheelDataSetups.checkoutMessage = checkoutMessage;
        wheelDataSetups.wheelLocations = wheelLocations;
        wheelDataSetups.noProductImage = noProductImage;
        wheelDataSetups.reservationCaptureAmount = reservationCaptureAmount;

        document.title = wheelDataSetups.title;
        return resp;
      })
    );
  }

  configUSer() {
    AppSession.user = {
      emailAddress: null,
      name: null,
      phoneNumber: null,
    };

    if (this._cookieService.check(AUTH_USER)) {
      let jsonUser = this._cookieService.get(AUTH_USER);
      try {
        const user = JSON.parse(jsonUser);
        this.store.dispatch(LoggedInAction({ user: user }));
      } catch (error) {}
    }

    if (AppSession.user.customerGuid) {
      this._cookieService.set(
        'Abp-CustomerId',
        AppSession.user.customerGuid,
        new Date(new Date().getTime() + 5 * 365 * 86400000),
        '/'
      );
    } else {
      this._cookieService.delete('Abp-CustomerId', '/');
    }
  }

  getWheelTokenOneMap() {
    return this.wheelSetupsService.getAddressByPostCode();
  }

  setCookieToken(name: string, value: string) {
    this._cookieService.set(name, value);
  }
}

export function StartUpFactory(
  startUpService: StartUpService,
  dineMessageService: DineMessageService
) {
  return () => {
    return new Promise<boolean>(async (resolve, reject) => {
      var leng = window.location.pathname.split('/');
      const tenantName = sessionStorage.getItem(CONSTANT.TENANT_NAME) as string;
      sessionStorage.removeItem(CONSTANT.DIALOG_SHOWN);

      startUpService.configUSer();
      if (!leng[1]) {
        reject('Tenant is not provided');
        dineMessageService?.showError('Invalid Url. Please enter correct Url');
        return;
      }
      const prms = await startUpService.getGatewayData(leng[1]);
      prms.toPromise().then((res) => {
        let gatewayData = cloneDeep(res) as IGatewayData;
        //  gatewayData.TenantUrl = 'http://localhost:7031';

        if (res && !isArray(res)) {
          AppConsts.getWaysJSON = gatewayData;
          sessionStorage.setItem(
            CONSTANT.GETWAYJSON,
            JSON.stringify(gatewayData)
          );
          sessionStorage.setItem(
            CONSTANT.TENANT_NAME,
            AppConsts.getWaysJSON.Code
          );
          sessionStorage.setItem(
            CONSTANT.REMOTEBASEURL,
            AppConsts.getWaysJSON.TenantUrl
          );
          sessionStorage.setItem(
            CONSTANT.TENANT_ID,
            AppConsts.getWaysJSON.TenantId.toString()
          );

          environment.selectedTenantName = AppConsts.getWaysJSON.Code;
          environment.remoteServiceBaseUrl = AppConsts.getWaysJSON.TenantUrl;
          environment.tenantId = AppConsts.getWaysJSON.TenantId;
          environment.splashImageUrl = gatewayData.WelcomeImage;
          environment.animationImage = gatewayData.AnimationImage;
          if (leng[1].toLowerCase() !== tenantName.toLowerCase()) {
            sessionStorage.clear();
            window.location.reload();
          }
        }
        resolve(true);
      });
    });
  };
}
