export interface ICategory {
  name: string;
  code?: string;
  organizationId?: number;
  tenantId?: number;
  productGroupId?: number;
  isDeleted?: true;
  deleterUserId?: number;
  deletionTime?: Date;
  lastModificationTime?: Date;
  lastModifierUserId?: number;
  creationTime?: Date;
  creatorUserId?: number;
  id: number;
}
