import * as moment from 'moment';

export interface IGetOrderDetailForView {
  orderDetail: IOrderDetail;
}
export interface IOrderDetail {
  creationTime: moment.Moment;
  checkoutStatus: number;
  amount: number;
  orderNotes: string;
  orderDetails: string;
  deliveryLocationId: string;
  building: string;
  street: string;
  flatNo: string;
  landmark: string;
  orderPaymentMethod: number;
  currency: string;
}
