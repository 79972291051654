import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AnimationRouteItems } from './core/common/constant/constant';
import { MainGuard } from './core/common/guard';
import {environment} from '../environments/environment';
import { RouteResolverService } from './core/common/resolvers/route.resolver';
import { AuthGuard, ForceAuthGuard } from './core/common/authenticate';



export const routes: Routes = [
  {
    path: `${environment.selectedTenantName}/account`,
    loadChildren: () => import('./modules/account/account.module').then((x) => x.AccountModule),
    canActivate: [AuthGuard],
    resolve: { data: RouteResolverService }
  },
  {
    path: `${environment.selectedTenantName}/my-profile`,
    loadChildren: () => import('./modules/my-profile/my-profile.module').then((x) => x.MyProfileModule),
    canActivate: [MainGuard],
    resolve: { data: RouteResolverService },
    data: { animation: AnimationRouteItems.myProfile }
  },
  {
    path: `${environment.selectedTenantName}/order`,
    loadChildren: () => import('./modules/order/order.module').then((x) => x.OrderModule),
    resolve: { data: RouteResolverService },
    canActivate: [ForceAuthGuard],
    canActivateChild:[ForceAuthGuard],
    data: { animation: AnimationRouteItems.myProfile }
  },
  {
    path: `queue`,
    loadChildren: () => import('./modules/queue/queue.module').then((x) => x.QueueModule),
    canActivate: [MainGuard],
    resolve: { data: RouteResolverService }
  },
  {
    path: `${environment.selectedTenantName}/location`,
    loadChildren: () => import('./modules/location/location.module').then(m => m.LocationModule),
    canActivate: [ForceAuthGuard],
    canActivateChild: [ForceAuthGuard],
    resolve: { data: RouteResolverService }
  },
  {
    path: `${environment.selectedTenantName}`,
    loadChildren: () => import('./modules/landing/landing.module').then((x) => x.LandingModule),
    resolve: { data: RouteResolverService }
  },
  {
    path: '',
    redirectTo: `${environment.selectedTenantName}`,
    resolve: { data: RouteResolverService },
    pathMatch:'full'
  },
  { path: '**', redirectTo: `${environment.selectedTenantName}` },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
