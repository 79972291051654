import * as moment from 'moment';

export interface IOrderCheckout {
  id?: number;
  lastModificationTime?: moment.Moment;
  lastModifierUserId?: number;
  orderNotes?: string;
  flatNo?: string;
  landmark?: string;
  building: string;
  street: string;
  postalCode: string;
  deliveryLocationId: number;
  creationTime: Date;
  amount: number;
  orderDetails: string;
  checkoutStatus: CheckoutStatus;
  orderPaymentMethod: OrderPaymentMethod;
  chargedToken: string;
  currency: string;
  paymentOption: string;
}
export interface ICheckoutModel {
  purcharsedOrderList: [
    {
      orderDate: Date,
      amount: 0,
      quantity: 0,
      address: string,
      menuItemsDynamic: string,
      wheelOrderType: 0
    }
  ],
  billingInfo: {
    name: string,
    emailAddress: string,
    address1: string,
    address2: string,
    address3: string,
    city: string,
    country: string,
    cityId: 0,
    countryId: 0,
    postcalCode: string,
    zone: 0
  },
  paymentOption: string,
  paidAmount: 0,
  callbackUrl: string,
  omiseToken: string,
  source: string,
  adyenEncryptedCardNumber: string,
  adyenEncryptedExpiryMonth: string,
  adyenEncryptedExpiryYear: string,
  adyenEncryptedSecurityCode: string,
  paypalOrderId: string,
  stripeToken: string,
  googlePayToken: string
}

export enum CheckoutStatus {
  Failed = 0,
  Expired = 1,
  Pending = 2,
  Successful = 3,
  Reversed = 4
}

export enum OrderPaymentMethod {
  CREDIT_CARD = 1,
  CASH_ON_DELIVERY = 2,
  CARD_ON_DELIVERY = 3,
  OTHER = 4,
}
