import { AppConsts, CONSTANT } from '../app/core/common';

export const environment = {
  production: true,
  appBaseUrl: 'http://localhost:4500',
  remoteServiceBaseUrl: sessionStorage.getItem(CONSTANT.REMOTEBASEURL),
  selectedTenantName: sessionStorage.getItem(CONSTANT.TENANT_NAME)
    ? sessionStorage.getItem(CONSTANT.TENANT_NAME)
    : gettenantName(),
  tenantId: sessionStorage.getItem(CONSTANT.TENANT_ID)
    ? parseInt(sessionStorage.getItem(CONSTANT.TENANT_ID) as any)
    : 1,
  sessionId: GetSessionId() ? GetSessionId() : GetSessionId(),
  gatewayUrl: 'https://dinegateway.com/api/gateway/read/0/',
  splashImageUrl: '',
  animationImage: '',
  encryptKey: 'vJwZkQd0kUmQHqy4IBmAog==',
  encryptIV: 'xVrEDucaz0m+PT==',
  firebaseConfig: {
    apiKey: 'AIzaSyAvWCFM5HBEbGiI1AIqgkFQUQ1CbSr_tmE',
    authDomain: 'e-dine.firebaseapp.com',
    databaseURL: 'https://e-dine-default-rtdb.firebaseio.com',
    projectId: 'e-dine',
    storageBucket: 'e-dine.appspot.com',
    messagingSenderId: '46341722219',
    appId: '1:46341722219:web:387bebb4b7e046c236612e',
  },
  isMultilocation: false,
};

function GetSessionId(): any {
  return JSON.parse(sessionStorage.getItem(CONSTANT.CURRENT_SESSION) as any)
    ?.sessionId;
}

function gettenantName(): any {
  var leng = window.location.pathname.split('/');
  if (AppConsts.getWaysJSON?.Code) {
    var tenantNameslist = AppConsts.getWaysJSON.Code;
    if (leng.some((r) => tenantNameslist.includes(r))) {
      var tenantName = leng.filter((x) => tenantNameslist.includes(x));
      let tenantObject = AppConsts.getWaysJSON.Code == tenantName[0];
      if (tenantObject) {
        return tenantName[0];
      }
    } else {
      return leng[1];
    }
  } else if (leng[1]) {
    sessionStorage.setItem(CONSTANT.TENANT_NAME, leng[1]);
    return leng[1];
  }
}
