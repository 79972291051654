import { createAction, props } from "@ngrx/store";
import { IWheelScreenItem, IWheelScreenItemsRequest, IWheelScreenItemsSearchRequest } from "../../model";
import { ApiResponse } from "../../model/api-response.model";
import { ICartItem } from "../../model/cart.model";

export const wheelCategoriesRequested = createAction('[Wheel Menu] Wheel Categories Requested', props<{ body: { id: number, tenantId: number } }>());
export const wheelCategoriesLoaded = createAction('[Wheel Menu] Wheel Categories Loaded', props<{ result: ApiResponse }>());

export const wheelSubCategoriesRequested = createAction('[Wheel Menu] Wheel Sub Categories Requested', props<{ body: { id: number, tenantId: number, locationId: number } }>());
export const wheelSubCategoriesLoaded = createAction('[Wheel Menu] Wheel Sub Categories Loaded', props<{catId: number, result: ApiResponse }>());

export const wheelScreenMenuItemsRequested = createAction('[Wheel Menu] Wheel Screen Menu Items Requested', props<{ body: IWheelScreenItemsRequest }>());
export const wheelScreenMenuItemsLoaded = createAction('[Wheel Menu] Wheel Screen Menu Loaded', props<{ pageNo: number, result: ApiResponse }>());

export const wheelScreenMenuItemsSearchRequested = createAction('[Wheel Menu] Wheel Screen Menu Items Search Requested', props<{ body: IWheelScreenItemsSearchRequest }>());
export const wheelScreenMenuItemsSearchLoaded = createAction('[Wheel Menu] Wheel Screen Menu Item Search Loaded', props<{ result: ApiResponse }>());

export const openAddItemToCartPopup = createAction('[Wheel Menu] Open Add Item to Cart Popup', props<{ item: IWheelScreenItem, cartItem?: ICartItem }>());
export const closeAddItemToCartPopup = createAction('[Wheel Menu] Close Add Item to Cart Popup');

export const toggleMenuViewType = createAction('[Wheel Menu] Toggle Menu View Type', props<{ isGridView: boolean }>());


export const apiResponseError = createAction('[Wheel Setup] check tenant available', props<{ error: any }>());

