export interface ILoginRequestModel {
  tenancyName: string;
  usernameOrEmailAddress: string;
  password: string;
}

export interface ILogingResponseModel {
  userId: number;
  tenantId: number;
  timeZoneUtc: number;
  timeZone: string;
  success: boolean;
  result: string;
  error: {
    code: number;
    message: string;
    details: string;
    validationErrors: string;
  };
}

export interface IUser {
  id: number;
  userName: string;
  emailAddress: string;
  phoneNumber: string;
  phonePrefix: string;
}

export interface ISignupRequestModel {
  name: string;
  email: string;
  phone: string;
  whatsapp: string;
  password: string;
  tenantId: number;
  tenantName: string;
  error: {
    code: number;
    message: string;
    details: string;
    validationErrors: string;
  };
}

export interface ISignupResponseModel {
  success: boolean;
  result: {
    token: string;
    userId: number;
  };
  error: string;
}

export interface ILoggedInUserInfo {
  id: number;
  cardId: number;
  code?: any;
  name: string;
  phoneNumber?: any;
  whatAppNumber?: any;
  email: string;
  onboardType: number;
  locationName?: any;
  locationId?: any;
  userId: number;
  attrData?: any;
  onlineCustomerSectionName?: any;
  onlineCustomerGradeName?: any;
  parent?: any;
  balance: {
    cardId: number;
    userId: number;
    balance: number;
    cardNumber: number;
  };
}
