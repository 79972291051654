import { Injectable } from '@angular/core';
import { CookieService, CookieOptions } from 'ngx-cookie';

@Injectable()
export class LocalCookieService {
  anotherTodolist = [];
  expires = new Date();

  constructor(private storage: CookieService) { }

  public set(data: any, STORAGE_KEY: string, options?: CookieOptions): void {

    const hasData = this.storage.get(STORAGE_KEY);

    if (hasData) {
      this.storage.remove(STORAGE_KEY);
    }
    if (options) {
      this.storage.put(STORAGE_KEY, data, options);
    } else {
      this.storage.put(STORAGE_KEY, data)
    }
  }

  get(STORAGE_KEY: string): string {
    return this.storage.get(STORAGE_KEY) || '';
  }

  getAndRemove(STORAGE_KEY: string): string {
    const val = this.storage.get(STORAGE_KEY) || '';
    if (val)
      this.storage.remove(STORAGE_KEY);

    return val;
  }

  remove(STORAGE_KEY: string) {
    this.storage.remove(STORAGE_KEY);
  }

  clearAll() {
    this.storage.removeAll();
    let doc = window.document;

    var cookies = doc.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      doc.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }

    var cookies = doc.cookie.split("; ");
    for (var c = 0; c < cookies.length; c++) {
      var d = window.location.hostname.split(".");
      while (d.length > 0) {
        var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
        var p = location.pathname.split('/');
        doc.cookie = cookieBase + '/';
        while (p.length > 0) {
          doc.cookie = cookieBase + p.join('/');
          p.pop();
        };
        d.shift();
      }
    }
  }
}
