import { AppSession, CONSTANT } from '../../core/common';
import { Injectable } from '@angular/core';
import { JwtService } from '../../core/common/authenticate/jwt.service';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of, pipe, throwError } from 'rxjs';
import {
  ILogingResponseModel,
  ILoginRequestModel,
  ISignupRequestModel,
  IUser,
} from '../model';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Guid } from 'guid-typescript';
import { IAppState } from '../../store';
import { Store } from '@ngrx/store';
import {
  HideSpinnerAction,
  ShowSpinnerAction,
} from '../store/actions/spinner.actions';
import { catchError, tap } from 'rxjs/operators';
import { ApiResponse } from '../model/api-response.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userSubject: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(
    this.getUserFromLocal()
  );

  user: Observable<IUser>;

  constructor(
    private _cookieService: CookieService,
    private http: HttpClient,
    private auth: JwtService,
    private store: Store<IAppState>
  ) {
    this.user = this.userSubject.asObservable();
  }

  login(model: ILoginRequestModel) {
    const spinnerId = Guid.create().toString();
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));

    return this.http.post(`/api/Account/Authenticate`, model).pipe(
      tap(() =>
        this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))
      ),
      catchError((err) => {
        this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
        return throwError(err);
      })
    );
  }

  signup(model: ISignupRequestModel) {
    const spinnerId = Guid.create().toString();
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));

    return this.http.post(`/api/services/app/FrontUser/ApiSignUp`, model).pipe(
      tap(() =>
        this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))
      ),
      catchError((err) => {
        this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
        return throwError(err);
      })
    );
  }

  forgotPassword(model: any): Observable<ApiResponse> {
    const spinnerId = Guid.create().toString();
    const url = `/api/services/app/FrontUser/ApiSendResetPasswordLink`;
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));
    return this.http.post<ApiResponse>(url, model).pipe(
      tap(() =>
        this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))
      ),
      catchError((err) => {
        this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
        return throwError(err);
      })
    );
  }

  setPasswordFromToken(model: any) {
    const spinnerId = Guid.create().toString();
    const url = `/api/services/app/FrontUser/ApiSetNewPasswordFromResetToken`;
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));
    return this.http.post<ApiResponse>(url, model).pipe(
      tap(() =>
        this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))
      ),
      catchError((err) => {
        this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
        return throwError(err);
      })
    );
  }

  changePassword(model: any) {
    const spinnerId = Guid.create().toString();
    const url = `/api/services/app/FrontUser/ChangePassword`;
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));
    return this.http.post<ApiResponse>(url, model).pipe(
      tap(() =>
        this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))
      ),
      catchError((err) => {
        this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
        return throwError(err);
      })
    );
  }

  getUserInfo(userId: number, tenantId: number): Observable<any> {
    return this.http
      .post<any>(`/api/services/app/FrontUser/GetUserInfo`, {
        userId,
        tenantId,
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  searchMember(
    searchText: string,
    tenantId: number,
    locationId: number
  ): Observable<ApiResponse> {
    const spinnerId = Guid.create().toString();
    const url = `/api/services/app/FrontMembership/ApiSearchMember`;
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));
    return this.http
      .post<ApiResponse>(url, {
        searchText,
        tenantId,
        wheelLocationId: locationId,
      })
      .pipe(
        tap(() =>
          this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))
        ),
        catchError((err) => {
          this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
          return throwError(err);
        })
      );
  }

  logout() {
    return of({} as ApiResponse);
  }

  getUserFromLocal() {
    return AppSession.user;
  }

  public get userValue(): IUser {
    return this.userSubject.value;
  }

  public get userToken() {
    if (sessionStorage.getItem(CONSTANT.AUTH)) {
      const user = JSON.parse(
        sessionStorage.getItem(CONSTANT.AUTH)
      ) as ILogingResponseModel;
      return user.result;
    }

    return this.auth.getUserToken();
  }

  public get customerId() {
    return this._cookieService.get('Abp-CustomerId');
  }
}
