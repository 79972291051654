import { animate, style, transition, trigger } from '@angular/animations';
import {  OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { BaseComponent } from './core/components/base.component';
import { FirebaseService, WheelSetupsService } from './core/service';
import {
  LoggedInAction,
  logoutAction,
} from './core/store/actions/auth.actions';
import { IAppState } from './store';
import { CONSTANT } from './core/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => active', [
        // using status here for transition
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 })),
      ]),
      transition('* => void', [animate(1000, style({ opacity: 0 }))]),
    ]),
  ],
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  preloader = true;
  animationImage = environment.animationImage;
  toggleLeftMenu: boolean;
  returnUrl: string;
  isDineIn : boolean = false;

  constructor(
    private fbService: FirebaseService,
    public translate: TranslateService,
    private wheelSetupService:WheelSetupsService,
    private router: Router,
    private route: ActivatedRoute,
    store: Store<IAppState>
  ) {
    super(store);
    setTimeout(() => {
      this.preloader = false;
      if (this.addOn.WheelCustomScript) {
        this.appendCustomScript(this.addOn.WheelCustomScript);
      }
    }, 2000);

    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');

    if (sessionStorage.getItem('lang') != null) {
      translate.use(sessionStorage.getItem('lang'));
    } else {
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    }
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      try {
        if (event instanceof NavigationEnd) {
          this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
          var elem = document.getElementsByClassName('body')[0];
          if (event.url.indexOf('menu') > -1) {
            elem.classList.add('body-extend');
          } else {
            elem.classList.remove('body-extend');
          }
        }
      } catch (e) {
        console.log(e);
      }
    });

    this.wheelSetupService.data$.subscribe((data) => {
      this.isDineIn = data;
    });

    this.fbService.signInToFb();
    this.fbService.initializeNotification();
  }

  gotoHome() {
    this.toggleLeftMenu = false;
    this.router.navigate([`/${this.tenantCode}`]);
  }

  continue() {
    this.toggleLeftMenu = false;
    this.router.navigateByUrl(`/${this.tenantCode}/location`);
  }

  login() {
    this.toggleLeftMenu = false;
    this.router.navigate([`/${this.tenantCode}/sign-in`], {
      queryParams: { returnUrl: this.router.url },
    });
  }

  signUp() {
    this.toggleLeftMenu = false;
    this.router.navigate([`/${this.tenantCode}/sign-up`], {
      queryParams: { returnUrl: this.router.url },
    });
  }

  logout() {
    this.store.dispatch(logoutAction());
  }

  gotoLocations() {
    this.toggleLeftMenu = false;
    this.router.navigate([`/${this.tenantCode}/location`]);
  }

  gotoDepartments() {
    this.toggleLeftMenu = false;
    this.router.navigate([
      `/${this.tenantCode}/location/${this.getLocationId()}/department`,
    ]);
  }

  toggleMenu() {
    if (sessionStorage.getItem(CONSTANT.AUTH) && !this.toggleLeftMenu) {
      const user = JSON.parse(sessionStorage.getItem(CONSTANT.AUTH));
      if (user) {
        this.store.dispatch(LoggedInAction({ user }));
      }
    }
    this.toggleLeftMenu = !this.toggleLeftMenu;
  }

  setLang(lang) {
    sessionStorage.setItem('lang', lang);
    this.translate.use(lang);
  }
  changePassword() {
    var routerURL = null;
    if (this.router.url.includes('entity')) {
      const returnUrlStartIndex = this.router.url.indexOf('returnUrl=');
      if (returnUrlStartIndex !== -1) {
        let returnUrl = this.router.url.substring(returnUrlStartIndex + 10); // +10 to skip 'returnUrl='
        routerURL = decodeURIComponent(returnUrl);
      } else {
        routerURL = this.router.url;
      }
    }
    this.toggleLeftMenu = false;
    this.router.navigate([`/${this.tenantCode}/change-password`], {
      queryParams: { returnUrl: routerURL },
    });
  }

  ngOnDestroy() {
    this.fbService.signOut();
  }
}
