import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { StatusDineMessage } from '../common/constant/shared.const';

@Injectable({
  providedIn: 'root'
})
export class DineMessageService {
  constructor() { }
  showSuccess(message: string): void {
    this.show(message);
  }
  showError(message: string, time?: number): void {
    this.show(message, StatusDineMessage.Error, time);
  }

  private show(message: string, status: string = StatusDineMessage.Success, time?: number): void {
    const messageRef = document.createElement('div');
    messageRef.classList.add('dine-message');
    const messageContent = message;
    if (status === StatusDineMessage.Success) {
      messageRef.innerHTML = this.getTemplateMessageSuccess(messageContent);
      messageRef.classList.add('success');
      document.querySelector('body')?.append(messageRef);
    } else {
      messageRef.innerHTML = this.getTemplateMessageError(messageContent);
      messageRef.classList.add('error');
      document.querySelector('body')?.append(messageRef);
    }
    let _time = time ? time : 3000;
    timer(_time).subscribe(() => {
      messageRef.remove()
    })
  }

  private getTemplateMessageError(message: string): string {
    return `
        <i class="pi pi-times"></i>
        <span>${message}</span>
        `
  }
  private getTemplateMessageSuccess(message: string): string {
    return `
        <i class="pi pi-check"></i>
        <span>${message}</span>
        `
  }
}
