import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { ITableReservationModel } from '../model';
import { AppConsts, CONSTANT, LocalStorageService } from '../common';
import { ApiResponse } from '../model/api-response.model';
import { WheelOrderType } from '../common/enums/wheel-order-type.enum';
import { SELECTED_DEPARTMENT } from '../common/constant/shared.const';
import { table } from 'console';

@Injectable({
  providedIn: 'root',
})
export class WheelSetupsService {
  public isDineIn = new BehaviorSubject<boolean>(false);
  public data$ = this.isDineIn.asObservable();

  updateData(newValue: boolean) {
    this.isDineIn.next(newValue);
  }

  getReservationId(resId: number) {
    let resInfo;
    if (sessionStorage.getItem(CONSTANT.RESERVATION_TABLE)) {
      resInfo = JSON.parse(
        sessionStorage.getItem(CONSTANT.RESERVATION_TABLE)
      ) as ITableReservationModel;
    }

    if (this.isBookingSession()) {
      return resInfo?.id || resId || 0;
    }
    return 0;
  }

  isBookingSession() {
    const selectedDepartment = JSON.parse(
      window.sessionStorage.getItem(SELECTED_DEPARTMENT)
    );
    if (selectedDepartment)
      return selectedDepartment.wheelOrderType === WheelOrderType.Reservation;
    return false;
  }

  getCurrentTableId(tId: number) {
    let tableId = 0;
    if (sessionStorage.getItem(CONSTANT.TABLE_ID)) {
      tableId = +sessionStorage.getItem(CONSTANT.TABLE_ID);
    }

    if (this.isTableSession()) {
      return tableId || tId || 0;
    }

    return 0;
  }

  isTableSession() {
    const selectedDepartment = JSON.parse(
      window.sessionStorage.getItem(SELECTED_DEPARTMENT)
    );
    if (selectedDepartment)
      return selectedDepartment.wheelOrderType === WheelOrderType.DineIn;
    return false;
  }

  constructor(
    private http: HttpClient,
    private sessionStorage: LocalStorageService
  ) { }

  getWheelSetups(tenantId: number): Observable<ApiResponse> {
    let url_ = `/api/services/app/frontWheel/ApiWheelSetup`;
    url_ = url_.replace(/[?&]$/, '');
    const content_ = {
      tenantId: tenantId,
      code: AppConsts.getWaysJSON?.SetupCode,
    };

    return this.http.post<ApiResponse>(url_, content_);
  }

  getCurrentSession(
    tenantId: number,
    tableId: number,
    wheelLocationId: number,
    reservationId: number,
    wheelDepartmentId: number,
    sessionId: string,
    forceTable?: boolean
  ): Observable<ApiResponse> {
    let url_ = `/api/services/app/frontWheel/ApiCurrentSession`;

    let tableIdParam = this.getCurrentTableId(tableId);
    if (!tableIdParam && forceTable && tableId) {
      tableIdParam = tableId;
    }

    let content_ = {
      tenantId: tenantId,
      wheelLocation: wheelLocationId,
      table: tableIdParam,
      sessionId: sessionId,
      reservationId: this.getReservationId(reservationId),
      wheelDepartment: wheelDepartmentId,
      isMultiLocation: environment.isMultilocation,
    };

    return this.http.post<ApiResponse>(url_, content_);
  }

  getFaqs(): Observable<ApiResponse> {
    let url_ = '/api/services/app/TiffinFaq/GetFaqs'.replace(/[?&]$/, '');

    return this.http.get<ApiResponse>(url_);
  }

  getAddressByPostCode(): Observable<any> {
    const url =
      'https://developers.onemap.sg/commonapi/search?searchVal=189970&returnGeom=Y&getAddrDetails=Y';

    return this.http.get(url, {
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
    });
  }

  isOpeningHour(id: number | undefined): Observable<ApiResponse> {
    let urlString = '/api/services/app/WheelOrder/IsOpeningHour?';
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      urlString += 'wheelDepartmentId=' + encodeURIComponent('' + id) + '&';
    }
    urlString = urlString.replace(/[?&]$/, '');

    return this.http.post<ApiResponse>(urlString, {});
  }

  getDefaultDateFuture(id: number | undefined): Observable<ApiResponse> {
    let urlString = '/api/services/app/WheelOrder/GetDefaultDateFuture?';
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      urlString += 'wheelDepartmentId=' + encodeURIComponent('' + id) + '&';
    }
    urlString = urlString.replace(/[?&]$/, '');

    return this.http.get<ApiResponse>(urlString, {});
  }

  calculateDeliveryTime(
    locationId: number | undefined,
    customerAddressLng: number | undefined,
    customerAddressLat: number | undefined
  ): Observable<ApiResponse> {
    let urlString = '/api/services/app/WheelOrder/CalculateDeliveryTime?';
    if (!locationId)
      console.log("The parameter 'departmentId' cannot be null.");
    else urlString += 'locationId=' + encodeURIComponent('' + locationId) + '&';
    if (!customerAddressLng)
      console.log("The parameter 'customerAddressLng' cannot be null.");
    else
      urlString +=
        'customerAddressLng=' +
        encodeURIComponent('' + customerAddressLng) +
        '&';
    if (!customerAddressLat)
      console.log("The parameter 'customerAddressLat' cannot be null.");
    else
      urlString +=
        'customerAddressLat=' +
        encodeURIComponent('' + customerAddressLat) +
        '&';

    urlString = urlString.replace(/[?&]$/, '');

    return this.http.post<ApiResponse>(urlString, {});
  }

  getGatewayInfo(code: string): Observable<any> {
    let urlString = `${environment.gatewayUrl}${code}`;
    urlString = urlString.replace(/[?&]$/, '');

    return this.http.get<any>(urlString);
  }
}
