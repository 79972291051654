import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { coreComponents } from ".";
import { materialUiModules } from "./common/constant/material-ui.modules";
import { RouteResolverService } from "./common/resolvers/route.resolver";
import { coreServices } from "./service";
import { effects } from "./store/effects";
import { authReducer, authStateKey } from "./store/reducers/auth.reducer";
import { commonReducer, commonStateKey } from "./store/reducers/common.reducers";
import { menuReducer, menuStateKey } from "./store/reducers/menu.reducers";
import { spinnerReducer } from "./store/reducers/spinner.reducer";
import { SafePipe } from './common/pipes/safe-html.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    StoreModule.forFeature(commonStateKey, commonReducer),
    StoreModule.forFeature(authStateKey, authReducer),
    StoreModule.forFeature('spinner', spinnerReducer),
    StoreModule.forFeature(menuStateKey, menuReducer),
    EffectsModule.forFeature([...effects]),
    ...materialUiModules,
  ],
  declarations: [...coreComponents, SafePipe],
  exports: [SafePipe],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: CoreModule,
      providers: [
        //AuthGuard,
        //ErrorHandlerService,
        //LocalCookieService,
        //EventInterceptorProvider,
        ...coreServices,
        //...fromVirtualEventsResolvers.resolvers,
        RouteResolverService
      ]
    };
  }
}
