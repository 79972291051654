export interface ICartItemRequestModel {
  locationId: number;
  departmentId: number;
  sessionId: string;
  includeSubTotal: boolean;
  voucherCode?: IVoucherCode;
  includeStopChange?: boolean;
  selectedAddress?: {
    address: string;
    latitude: string;
    longitude: string;
  };
}

export interface IVoucherCode {
  voucherId: number;
  groupId: number;
}
