<ng-container *ngIf="isWokHey; else commonSpinner">
  <ngx-spinner [fullScreen]="false" bdColor="#fff" template="<img src='{{
      animationImage
    }}' style='height: auto;width: 370px;' />">
  </ngx-spinner>
</ng-container>

<ng-template #commonSpinner>
  <ngx-spinner [fullScreen]="false" bdColor="{{ alternateColor }}"
    template="<img src='{{ animationImage }}' style='height: 200px;' />">
  </ngx-spinner>
</ng-template>

<ng-container *ngIf="preloader; else showRoute">
  <app-preloader @fade></app-preloader>
</ng-container>

<div class="container">
  <ng-template #showRoute>
    <router-outlet></router-outlet>
  </ng-template>

  <ng-container *ngIf="!hideBranding">
    <div class="edine-by-levelfive" *ngIf="!preloader">
      <span>eDine -&nbsp;Powered by -&nbsp;<a href="https://hashmato.com" target="_blank">Hashmato</a>
      </span>
    </div>
  </ng-container>
</div>

<section class="fixed-area side-bar-area" *ngIf="loginRequired || forceLogin">
  <header>
    <nav class="navbar navbar-2 menu-bar" [style.background]="baseColor">
      <!-- Navbar Left -->
      <div class="navbar-left mr-auto" (click)="toggleMenu()">
        <div class="hamburger-menu" [class.active]="toggleLeftMenu">
          <div class="bar-top"></div>
          <div class="bar-middle"></div>
          <div class="bar-bottom"></div>
        </div>
      </div>
    </nav>
    <!-- Side Menu -->
    <div class="side__menu" [style.display]="toggleLeftMenu ? 'block' : 'none'">
      <ul class="__go-in">
        <ng-container *ngIf="loginRequired">
          <li *ngIf="!isLoggedIn">
            <a class="cursor-pointer" (click)="login()"><span [innerHtml]="'signIn' | translate">Sign In</span></a>
          </li>

          <li *ngIf="!isLoggedIn">
            <a class="cursor-pointer" (click)="signUp()"><span [innerHtml]="'signUp' | translate">Sign Up</span></a>
          </li>
        </ng-container>
      </ul>
      <ul class="__explore">
        <li *ngIf="isLoggedIn && loggedInUser.balance">
          <div>
            <strong>User Balance:</strong> {{ loggedInUser.balance.balance }}
          </div>
        </li>
        <li>
          <a href="javascript:" (click)="gotoHome()"><span [innerHtml]="'home' | translate">Home</span></a>
        </li>
        <li *ngIf="!this.serviceSetup && !isDineIn">
          <a href="javascript:" (click)="gotoLocations()">
            <span [innerHtml]="'locations' | translate">Location(s)</span>
          </a>
        </li>
        <li *ngIf="selectedLocationId && !this.serviceSetup && !isDineIn">
          <a href="javascript:" (click)="gotoDepartments()">
            <span [innerHtml]="'departments' | translate">Department(s)</span>
          </a>
        </li>
        <li *ngIf="isLoggedIn" (click)="toggleMenu()" [routerLink]="['/' + tenantCode + '/order/order-history']">
          <a href="javascript:">
            <span [innerHtml]="'orderHistory' | translate">Order History</span>
          </a>
        </li>
        <li *ngIf="isLoggedIn">
          <a class="cursor-pointer" (click)="changePassword()"><span>Change Password</span></a>
        </li>
        <li *ngIf="isLoggedIn">
          <a href="javascript:" (click)="logout()"><span [innerHtml]="'logout' | translate">Log Out</span></a>
        </li>
      </ul>
    </div>
  </header>
</section>
