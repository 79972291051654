import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ISpinnerState } from '../reducers/spinner.reducer';

export const selectSpinnerState = createFeatureSelector<ISpinnerState>('spinner');

export const selectShownSpinnerCount = createSelector(
  selectSpinnerState,
  spinnerState => spinnerState.count,
);

export const selectIsSpinnerShown = createSelector(
  selectShownSpinnerCount,
  count => count > 0,
);
