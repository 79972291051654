import {
  NgModule,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  Injector,
  ErrorHandler
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MicroSentryModule } from '@micro-sentry/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store';
import { EffectsModule } from '@ngrx/effects';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from './core/core.module';
import { CookieModule, CookieService } from 'ngx-cookie';
import { StartUpFactory, StartUpService } from './core/common/config/start-up/start-up.service';
import { CUSTOM_DATE_FORMATS, DineMessageService, HttpClientService, LocalStorageService } from './core/common';
import { LocationService, ScriptLoaderService } from './core/service';
import { AppInterceptor } from './core/common/authenticate';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { rootComponents } from './modules';
import { ServiceLocator } from './core/service/base-service-locator';
import { ClipboardModule } from 'ngx-clipboard';

export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, `${window.location.origin}/assets/i18n/`, '.json');
};

@NgModule({
  declarations: [AppComponent, ...rootComponents],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule.forRoot(),
    CookieModule.forRoot(),
    MatSidenavModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    NgxSpinnerModule,
    InfiniteScrollModule,
    ClipboardModule,
    NgxQRCodeModule,
    NgxSkeletonLoaderModule.forRoot({
      theme: {
        extendsFromRoot: true
      },
    }),
    MicroSentryModule.forRoot({
      dsn: 'https://85ac57f6b24c4fe2bb8cb6057a96951b@lfsmite.cc//2',
      environment: "production",
      release: "1.0.0"
    }),
    EffectsModule.forRoot([]),
    StoreModule.forRoot(reducers, {
      metaReducers: [],
      runtimeChecks: {},
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
        maxAge: 25,
        logOnly: environment.production,
      })
      : [],
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    ToastrModule.forRoot({
      timeOut: 30000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      enableHtml: true,

    })
  ],
  providers: [
    CookieService,
    StartUpService,
    LocalStorageService,
    HttpClientService,
    ScriptLoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: StartUpFactory,
      deps: [StartUpService, DineMessageService],
      multi: true,
    },
    LocationService,
    {
      provide: MAT_DATE_FORMATS,
      useValue: CUSTOM_DATE_FORMATS
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class AppModule {
  constructor(private injector: Injector) {    // Create global Service Injector.
    ServiceLocator.injector = this.injector;
  }

}
