import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ILoggedInUserInfo } from "../../model";
import { IAuthState } from "../reducers/auth.reducer";
import { commonStateKey, ICommonState } from "../reducers/common.reducers";

export const selectAuthState = state => state.authStateKey as IAuthState;
export const selectCommonState = createFeatureSelector<ICommonState>(commonStateKey);

export const selectIsLoggedIn = createSelector(
  selectAuthState,
  auth => auth?.isLoggedIn
);

export const selectLoggedInUserInfo = createSelector(
  selectAuthState,
  auth => auth?.userInfo as ILoggedInUserInfo
);

export const forceLogin = createSelector(
  selectAuthState,
  auth => auth?.isLoggedIn
);

