
export enum WheelOrderType {
  PickUp = 0,
  Delivery = 1,
  DineIn = 2,
  Reservation = 3
};


export enum TokenType {
  META_TOKEN = 1,
  NORMAL_TOKEN = 2,
};


export enum ItemType {
  MenuItem = 1,
  Combo = 2,
};

export enum WheelOrderStatus {
  OrderReceived = 0,
  Rejected = 1,
  Accepted = 2,
  Preparing = 3,
  Prepared = 4,
  Delivering = 5,
  Delivered = 6
}

export enum WheelVoucherType {
  Percentage = 0,
  Value = 1,
  Item = 2
}
