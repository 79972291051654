import { AuthEffect } from "./auth.effect";
import { CommonEffect } from "./common.effect";
import { MenuEffect } from "./menu.effect";
import { SpinnerEffects } from "./spinner.effects";

export const effects = [
  CommonEffect,
  AuthEffect,
  SpinnerEffects,
  MenuEffect
];
