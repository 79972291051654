export function prepareGetUrl(
  url_: string,
  filter: string | null | undefined,
  organizationId: number | undefined,
  sorting: string | null | undefined,
  maxResultCount: number | undefined,
  skipCount: number | undefined
) {
  if (filter !== undefined)
    url_ += 'Filter=' + encodeURIComponent('' + filter) + '&';
  if (organizationId === null)
    throw new Error("The parameter 'organizationId' cannot be null.");
  else if (organizationId !== undefined)
    url_ += 'OrganizationId=' + encodeURIComponent('' + organizationId) + '&';
  if (sorting !== undefined)
    url_ += 'Sorting=' + encodeURIComponent('' + sorting) + '&';
  if (maxResultCount === null)
    throw new Error("The parameter 'maxResultCount' cannot be null.");
  else if (maxResultCount !== undefined)
    url_ += 'MaxResultCount=' + encodeURIComponent('' + maxResultCount) + '&';
  if (skipCount === null)
    throw new Error("The parameter 'skipCount' cannot be null.");
  else if (skipCount !== undefined)
    url_ += 'SkipCount=' + encodeURIComponent('' + skipCount) + '&';
  url_ = url_.replace(/[?&]$/, '');

  return url_;
}
