import { createFeatureSelector, createSelector } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { IWheelScreenCategory, IWheelScreenItem } from '../../model';
import { IMenuState, menuStateKey } from '../reducers/menu.reducers';

export const selectMenuState = createFeatureSelector<IMenuState>(menuStateKey);

export const selectCategories = createSelector(
  selectMenuState,
  menuState => cloneDeep(menuState.categories) as Array<IWheelScreenCategory>
);

export const selectSubCategories = (catId) => createSelector(
  selectMenuState,
  menuState => menuState.subCategories[catId] && cloneDeep(menuState.subCategories[catId].data) as Array<string>
);

export const selectMenuItems = (pageNo) => createSelector(
  selectMenuState,
  menuState => cloneDeep(menuState.menuItems[pageNo]?.data) as Array<IWheelScreenItem>
);

export const selectLastMenuRequest = createSelector(
  selectMenuState,
  menuState => menuState.lastMenuItemRequest
);

export const selectAddItemPopup = createSelector(
  selectMenuState,
  menuState => menuState.popupItem
);

export const selectViewType = createSelector(
  selectMenuState,
  menuState => menuState.isGridView
);


