import { ICartItem } from "../cart.model";

export interface ICartItemActionModel {
  key?: string;
  screenMenuItemId?: number;
  sessionId: string;
  tenantId: number;
  departmentId: number;
  locationId: number;
  item?: ICartItem,
  selectedAddress?:any
}
