import { WheelVoucherType } from "../../common/enums/wheel-order-type.enum";
import { ICartItem } from "../cart.model";
import { IWheelScreenItem } from "../menu-models/wheel-menu-category.model";

export interface ICartData {
  cartItem: ICartItem,
  item: IWheelScreenItem;
}

export interface IWheelDeliveryLocation {
  address: string;
  latitude: number;
  longitude: number;
  placeId: string;
}

export interface IValidatePromoCodeInput {
  tenantId: number;
  wheelLocationId: number;
  promoCode: string;
}

export interface IValidatePromoCodeOutput {
  id: number;
  groupId: number;
  name: string;
  type: WheelVoucherType;
  voucherValue: number;
  voucherCode: string;
  errorMessage: string;
}

