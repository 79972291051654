export interface IResetPasswordInput {
  userId?: number;
  resetCode?: string | undefined;
  password: string | undefined;
  returnUrl?: string | undefined;
  singleSignIn?: string | undefined;
  c?: string | undefined;
  passwordRepeat?: string;
}
export interface IResetPasswordOutput {
  canLogin: boolean;
  userName: string | undefined;
}
export interface ISendPasswordResetCodeInput {
  emailAddress: string | undefined;
}
export interface IResolveTenantIdInput {
  c: string | undefined;
}