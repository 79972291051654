import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WheelLocationsService } from './wheel-locations.service';
import { SELECTED_DEPARTMENT } from '../common/constant/shared.const';
import { CONSTANT, wheelDataSetups } from '../common';

@Injectable({
  providedIn: 'root',
})
export class PersistanceService {

  constructor(private http: HttpClient,
    private wheelLocationsService: WheelLocationsService,) {
  }

  selectLocation(locationId: number): void {
    const location = wheelDataSetups.wheelLocations.find(f => f.id === locationId);
    if (location) {
      sessionStorage.setItem(CONSTANT.LOCATION, JSON.stringify(location));
    }
  }

  loadDepartmentById(departmentId: number, tenantId: number, locationId: number): void {
    this.wheelLocationsService
      .getWheelDepartment(departmentId, tenantId, locationId)
      .subscribe((res) => {
        sessionStorage.setItem(SELECTED_DEPARTMENT, JSON.stringify(res.result));
      });

  }

}
