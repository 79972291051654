import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { IAppState } from '../../../store';
import { selectIsLoggedIn } from '../../store/selectors/auth.selector';
import { selectForceLogin } from '../../store/selectors/common.selectors';

@Injectable({ providedIn: 'root' })
export class ForceAuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private store: Store<IAppState>,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let url = state.url;

    return combineLatest([
      this.store.select(selectIsLoggedIn),
      this.store.select(selectForceLogin)
    ]).pipe(
      map(([loggedIn, force]) => ({ loggedIn, force })),
     // filter(({ loggedIn, force }) => loggedIn != null && force != null),
      map(v => {
        // both a and b are true
        if (!v.loggedIn && v.force) {
          this.router.navigate([`${environment.selectedTenantName}/sign-in`], { queryParams: { returnUrl: url } });
          return false;
        } else {
          return true;
        }
      }),
      first()
    );


  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let url = state.url;
    return combineLatest([
      this.store.select(selectIsLoggedIn),
      this.store.select(selectForceLogin)
    ]).pipe(
      map(([loggedIn, force]) => ({ loggedIn, force })),
     // filter(({ loggedIn, force }) => loggedIn !== null && force !== null),
      map(v => {
        if (!v.loggedIn && v.force) {
          this.router.navigate([`${environment.selectedTenantName}/sign-in`], { queryParams: { returnUrl: url } });
          return false;
        } else {
          return true;
        }
      }),
      first()
    );
  }


}
