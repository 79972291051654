<i class="material-icons close-btn" (click)="onDismiss()">close</i>

<div class="p-4">
  <h4 mat-dialog-title>
    {{title}}
  </h4>

  <div mat-dialog-content>
    <div class="flex-row">
      <div [innerHTML]="message"></div>
    </div>
  </div>


  <div class="actions-position mt-3" mat-dialog-actions>
    <button class="btn-default mat-default mat-raised-button"
            (click)="onDismiss()">
      {{ cancelText }}
    </button>

    <button class="confirm-btn pull-right mat-raised-button btn-primary"
            mat-raised-button color="primary" (click)="onConfirm()">
      {{ confirmText }}
    </button>
  </div>
</div>
