import { IGatewayData } from '../../../core/model/gateway.model';
import {
  IItemPriceModel,
  IWheelCurrentSession,
  IWheelDepartment,
} from '../../model';
export const MY_DATE_FORMAT = 'yyyy-MM-dd';

export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export enum CONSTANT {
  TEXT = 1,
  STORAGE_KEY = 'orderItems',
  DELIVERY_LOCALTION_KEY = 'deliveryLocation',
  SELECTED_TIME_SLOT = 'selectedTimeSlot',
  USERKEY = 'validUser',
  META_DATA_TOKEN_KEY = 'metadataAuthenticate',
  USER_TOKEN_KEY = 'userAuthenticate',
  TENANT_AVAILABLE_KEY = 'tenantAvailable',
  ADDRESS_DETAIL = 'addressDetail',
  CART_ORDER = 'CART_ORDER',
  DISPlAY_ONLY = 'displayOnly',
  CURRENT_SESSION = 'currentSession',
  TENANT_NAME = 'tenantName',
  TENANT_ID = 'tenantId',
  REMOTEBASEURL = 'url',
  DINEINTYPE = 'dineinType',
  INITIALTABLEURL = 'initialTableUrl',
  TABLE_ID = 'TABLE_ID',
  TABLE_NAME = 'TABLE_NAME',
  TABLE_OTP = 'TABLE_OTP',
  GETWAYJSON = 'gateWayJson',
  SELECTEDCOORDINATES = 'selectedCoordinates',
  DATE_TIME_DELIVERY = 'dateTimeDelivery',
  LOCATION = 'SELECTED_LOCATION',
  AUTH = 'Auth',
  ONLINE_CUSTOMER = 'ONLINE_CUSTOMER',
  RESERVATION_TABLE = 'RESERVATION_TABLE',
  CHECKOUT_DATA = 'CHECKOUT_DATA',
  CHECKOUT_MEMBER = 'CHECKOUT_MEMBER',
  PHONE_NUMBER = 'PHONE_NUMBER',
  PROMO_CODE = 'PROMO_CODE',
  PENDING_ORDER = 'PENDING_ORDER',
  USER_EMAIL = 'USER_EMAIL',
  MENU_ID = 'MENU_ID',
  DIALOG_SHOWN = 'DIALOG_SHOWN',
}

export enum PAYMENT_METHOD {
  CREDIT_CARD = 'creditCard',
  CASH_ON_DELIVERY = 'cashOnDelivery',
  CARD_ON_DELIVERY = 'cardOnDelivery',
}
export enum PAGE {
  Depaterment = 0,
  PostalCode = 1,
  Location = 2,
  PhoneNumber = 3,
  Language = 4,
  MenuOrder = 5,
  Payment = 6,
  PaymentInfor = 7,
  DateOrderFututer = 8,
  VerifyCode = 9,
}

export class AppConsts {
  static getWaysJSON: IGatewayData = sessionStorage.getItem(CONSTANT.GETWAYJSON)
    ? JSON.parse(sessionStorage.getItem(CONSTANT.GETWAYJSON) as any)
    : {};

  static readonly localization = {
    defaultLocalizationSourceName: 'DineConnect',
  };

  static readonly authorization = {
    encrptedAuthTokenName: 'enc_auth_token',
  };
}

export class wheelDataSetups {
  static checkOutPageId: number;
  static homePageId: number;
  static id: number;
  static logoCompanyUrl: string;
  static welcomeUrl: string;
  static advertOneUrl: string;
  static advertTwoUrl: string;
  static locations: Array<IWheelLocation>;
  static homePageObject: Object;
  static currency: string;
  static oneMapToken: string;
  static title: string;
  static welcomeMessage: string;
  static checkoutMessage: string;
  static noProductImage: string;

  static termsAndConditions: string;
  static wheelPostCodeServiceType: number;
  static isDisplayHomePage: boolean;
  static countryCode: string;
  static reservationCaptureAmount: number;
  static pageTitle: string;
  static wheelLocations?: Array<IWheelLocation>;
}

export interface IWheelLocation {
  id: number;
  name: string;
  enabled: boolean;
  externalMemberSearchEnabled: boolean;
  taxInclusive: boolean;
  outsideDeliveryZone: boolean;
  locationId: number;
  displayMenu: boolean;
  addressLatitude: number;
  addressLongitude: number;
  distanceRestrictInMeters: number;
  restrictDeliveryByZones: boolean;
  waitingTime: string;
  waitingTimeText: string;
  addOns: string;
  locationLogo: string;
  addOnsJson: IWheelLocationAddOns;
  wheelDepartments: Array<IWheelDepartment>;
  locGuid: any;
}

export interface IWheelLocationAddOns {
  GoogleLink: string;
  HomePageBanner: string;
  BackgroundColor: string;
  BackgroundImage: string;
  BannerGif: string;
  IsBackgroundColor: boolean;
  HideLocationsBackLink: boolean;
  OutletGstCode: string;
  OutletAddress: string;
  LocationLogo: string;
}

export class StepName {
  static chooseLocation = 'Choose Location';
  static chooseOrderType = 'Choose Order Type';
  static menu = 'Menu';
  static combo = 'Combo';
  static myOrder = 'My Order';
  static login = 'Customer Login';
  static signUp = 'Sign Up';
  static myProfile = 'My Profile';
  static guestInfo = 'Guest Information';
  static payment = 'Payment';
  static orderStatus = 'Order Status';
}

export class AnimationRouteItems {
  static home = 'Home';
  static location = 'Location';
  static orderType = 'OrderType';
  static menuItems = 'MenuItems';
  static combo = 'Combo';
  static login = 'SignIn';
  static signUp = 'SignUp';
  static myProfile = 'MyProfile';
}

export class RegexPatterns {
  static emailRegex =
    /^([A-Za-z0-9_\-\.]){1,}\@([A-Za-z0-9_\-\.]){1,}\.([A-Za-z]){2,4}$/;
}

export interface SelectCollapseData {
  label: string;
  value: string;
  icon: string;
  active?: boolean;
  url?: string;
}

export class AppSession {
  static user: any;
  static isGuest: boolean;
}

export class OrderSetting {
  static accepted: boolean;
  static width: number;
  static height: number;
  static autoRequestDeliver: boolean;
  static askTheMobileNumberFirst: boolean;
  static signUp: boolean;
}

export const infoTitles = ['Mr.', 'Mrs.', 'Mdm.', 'Dr.', 'Mx.'];
export const adultsList = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
export const childrenList = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

export const ItemPrices: Array<IItemPriceModel> = [
  {
    price: '$1',
    value: 1,
    isSelected: false,
  },
  {
    price: '$25',
    value: 25,
    isSelected: false,
  },
  {
    price: '$50',
    value: 50,
    isSelected: false,
  },
  {
    price: '$100',
    value: 100,
    isSelected: false,
  },
  {
    price: '$150',
    value: 150,
    isSelected: false,
  },
  {
    price: '$200',
    value: 200,
    isSelected: false,
  },
  {
    price: '$300',
    value: 300,
    isSelected: false,
  },
  {
    price: '$400',
    value: 400,
    isSelected: false,
  },
  {
    price: '$500',
    value: 500,
    isSelected: false,
  },
  {
    price: '$1000',
    value: 1000,
    isSelected: false,
  },
];
