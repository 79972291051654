import { json } from 'express';

export interface IWheelDepartment {
  id: number;
  name: string;
  wheelOrderType: number;
  menuId: number;
  displayHomePage: boolean;
  isEnableTable: boolean;
  isEnableDelivery: boolean;
  futureOrderDaysLimit: number;
  futureOrderDaysRestriction: number;
  timeSlots?: Array<IWheelTimeSlot>;
  paymentMethods?: Array<IWheelPaymentMethod>;
  dateDefaultOrderFututer?: boolean;
  allowPreOrder?: boolean;
  locationId: number;
  hasMultiMenu: boolean;
  welcomeMessage: string;
}

export interface IWheelTimeSlot {
  id: number;
  tenantId: number;
  timeFrom: string;
  timeTo: string;
  numberOfOrder: number;
  wheelDepartmentId: number;
  isOpened: boolean;
}

export class IWheelPaymentMethod {
  logo: string;
  id: number;
  orgId: number;
  name: string;
  systemName: string;
  settings: string;
  settingsModel: IWheelPaymentSettings;
  paymentTypeId: number;
  tenderType: number;
  serviceCharge: number;
  percentage: boolean;
  parseSettings() {
    this.settingsModel = JSON.parse(this.settings) as IWheelPaymentSettings;
  }
}

export interface IWheelPaymentSettings {
  PublishKey: string;
  SecretKey: string;
  EnablePayNow: boolean;
  HideCard: boolean;
  EnableOpnPaymnentLink: boolean;
  Enable3DSecure: boolean;
  PaymentLinkOnly: boolean;
  ProductName: string;
  MerchantId: number;
  AccessCode: string;
  Key: string;
  PostUrl: string;
  ApiKey: string;
  LiveApiUrlPrefix: string;
  MerchantAccount: string;
  ClientKey: string;
  Currency: string;
  HMacKey: string;
}
