import { Injectable } from '@angular/core';
import {
  switchMap,
  map,
  catchError,
  filter,
  mergeMap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { apiResponseError, cartItemsLoaded } from '../actions/common.actions';
import { WheelMenuService } from '../../service/wheel-menu.service';
import { wheelCategoriesLoaded, wheelCategoriesRequested, wheelScreenMenuItemsLoaded, wheelScreenMenuItemsRequested, wheelSubCategoriesLoaded, wheelSubCategoriesRequested, wheelScreenMenuItemsSearchRequested, wheelScreenMenuItemsSearchLoaded } from '../actions/menu.actions';
import { IWheelScreenItemsRequest, IWheelScreenItemsSearchRequest } from '../../model';


@Injectable()
export class MenuEffect { 

  @Effect()
  loadCategories$ = this.actions$.pipe(
    ofType(wheelCategoriesRequested),
    map(action => action.body),
    switchMap((payload: { id: number, tenantId: number}) => {
      return this.menuService.loadWheelScreenCategories(payload.id, payload.tenantId).pipe(
          map(
            (result: any) => wheelCategoriesLoaded(
              {
                result
              })),
          catchError(
            error => of(apiResponseError(error))),
        );
    }));

  @Effect()
  loadSubCategories$ = this.actions$.pipe(
    ofType(wheelSubCategoriesRequested),
    map(action => action.body),
    switchMap((payload: { id: number, tenantId: number, locationId: number}) => {
      return this.menuService.loadWheelScreenSubCategories(payload.id, payload.tenantId, payload.locationId).pipe(
          map(
            (result: any) => wheelSubCategoriesLoaded(
              {
                catId: payload.id,
                result: result
              })),
          catchError(
            error => of(apiResponseError(error))),
        );
    }));

  @Effect()
  loadMenuItems$ = this.actions$.pipe(
    ofType(wheelScreenMenuItemsRequested),
    map(action => action.body),
    mergeMap((payload: IWheelScreenItemsRequest) => {
      return this.menuService.loadWheelScreenItems(payload).pipe(
          map(
            (result: any) => wheelScreenMenuItemsLoaded(
              {
                pageNo: payload.pageNo,
                result: result
              })),
          catchError(
            error => of(apiResponseError(error))),
        );
    }));

    @Effect()
  loadMenuItemsSearch$ = this.actions$.pipe(
    ofType(wheelScreenMenuItemsSearchRequested),
    map(action => action.body),
    switchMap((payload: IWheelScreenItemsSearchRequest) => {
      return this.menuService.loadWheelScreenItemsSearch(payload).pipe(
          map(
            (result: any) => wheelScreenMenuItemsSearchLoaded(
              {
                result: result
              })),
          catchError(
            error => of(apiResponseError(error))),
        );
    }));


  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private menuService: WheelMenuService) {
  }


}
