export const LOGO_URL: string = 'LOGO_URL';
export const CHOOSE_TABLE_MANUAL: string = 'CHOOSE_TABLE_MANUAL';
export const SCAN_QR_CODE: string = 'SCAN_QR_CODE';
export const SELECTED_ADDRESS: string = 'SELECTED_ADDRESS';
export const SELECTED_DEPARTMENT: string = 'SELECTED_DEPARTMENT';
export const SELECTED_TABLE: string = 'SELECTED_TABLE';

export const SELECTED_FEE: string = 'SELECTED_FEE';
export const CHOOSE_TABLE_BY_QR: string = 'CHOOSE_TABLE_BY_QR';
export const LANGUAGE_DISPLAY: string = 'LANGUAGE_DISPLAY';
export const POSTAL_CODE_ONEMAP: string = 'POSTAL_CODE_ONEMAP';
export const POSTAL_CODE: string = 'POSTAL_CODE';
export const TIME_SLOT_DELEVERY: string = 'TIME_SLOT_DELEVERY';
export const SETTING_TENANT: string = 'SETTING_TENANT';
export const SAVED_OTP: string = 'SAVED_OTP';
export const GUEST_PHONE_NUMBER: string = 'GUEST_PHONE_NUMBER';
export const AUTH_USER: string = 'AUTH_USER';

export const GOOGLE_MAP_KEY = 'AIzaSyBt0qFOiZdKfMw1sWdqHWVwjy2h8iCd_Tg';
export const GOOGLE_API_KEY = 'AIzaSyBt0qFOiZdKfMw1sWdqHWVwjy2h8iCd_Tg';

export enum StatusDineMessage {
  Success = 'Success',
  Error = 'Error',
}
export enum PaymentMethodsSytemsNames {
  Cash = 'Cash',
  Omise = 'Omise',
  Stripe = 'Stripe',
  CCAvenue = 'CCAvenue',
  Adyen = 'Adyen',
}

export enum PaymentMethods {
  Cash = 'Cash',
  Omise = 'Omise',
  Stripe = 'Stripe',
  CCAvenue = 'CCAvenue',
  MSwipe = 'MSwipe',
  Adyen = 'Adyen',
  PREPAID_CARD_PROCESSOR = 'PREPAID_CARD_PROCESSOR',
  IPay88 = 'IPay88',
}

export const languageItemList: Array<{
  label: string;
  value: string;
  icon: string;
  active?: boolean;
}> = [
  {
    label: 'ENGLISH',
    value: 'EN',
    icon: 'assets/image/en-icon.png',
  },
];
export const MenuItemTagsConst: any[] = [
  { value: 'HOT', src: '../../../assets/image/itemtag/hot.svg' },
  {
    value: 'VEGETARIAN',
    src: '../../../assets/image/itemtag/vegetarian.svg',
  },
  {
    value: 'HALAI',
    icon: '',
    src: '../../../assets/image/itemtag/halal.svg',
  },
  {
    value: 'HOTPROMOTION',
    src: '../../../assets/image/itemtag/hot-promotions.svg',
  },
  {
    value: 'VEGAN',
    icon: '',
    src: '../../../assets/image/itemtag/vegan.svg',
  },
  {
    value: 'BESTSELLER',
    src: '../../../assets/image/itemtag/best-seller.svg',
  },
  {
    value: 'GLUTENFEE',
    icon: '',
    src: '../../../assets/image/itemtag/gluten.svg',
  },
];
