import { CustomMenuItemData } from './custom-menu-item.model';
import * as moment from 'moment';

export interface MenuItemData {
  menuItemId: any;
  name: string;
  published: boolean;
  image: string;
  price: any;
  order: number;
  parentId: number;
  categoryId: number;
  subCategory: string;
  screenMenuId: number;
  displayAs: number;
  portions: Portions[];
  orderTags: OrderTags[];
  lastModificationTime: moment.Moment;
  lastModifierUserId: number;
  creationTime: moment.Moment;
  creatorUserId: number;
  id: number;
  items: MenuItemData[];
  sectionChildren: MenuItemData[];
  wheelComboGroups: WheelComboGroup[];
  isComboItem?: boolean;
  wheelMenuComboName?: string;
  tagLabel?: string;
  note?: string;
  total?: number;
  addPrice?: number;
  totalDisplay?: number;
  totalPricePerItem?: number;
  recommendedItems?: IRecommendItem[];
  tags?: any;
  description: string;
  displayNutritionFacts: boolean;
  calories: string;
  nutritionInfo: string;
  quantity?: number;
  autoSelect?: boolean;
  addSeperately?: boolean;
  selected: boolean;
  numberOfColumns: number;
  isSoldOut: boolean;
  isCombo: boolean;
  screenMenuItemId: number;
}

export interface Portions {
  name: string;
  multiplier: number;
  price: any;
  itemId: number;
  tenantId: number;
  lastModificationTime: Date;
  lastModifierUserId: number;
  creationTime: Date;
  creatorUserId: number;
  checked: boolean;
  id: number;
}

export interface OrderTags {
  id: number;
  name: string;
  sortOrder: number;
  maxSelectedItems: number;
  minSelectedItems: number;
  addTagPriceToOrderPrice: boolean;
  saveFreeTags: boolean;
  freeTagging: boolean;

  taxFree: boolean;
  prefix: string;
  displayLocation: string;
  locations: string;
  departments: string;
  displayDepartments: string;
  items: Tag[];
  tags: Tag[];
  isShowOrderTags?: boolean;
  expanded: boolean;
}

export interface Tag {
  total: number;
  id: number;
  name: string;
  aliasName: string;
  price: any;
  menuItemId: number;
  maxQuantity: number;
  sortOrder: number;
  orderTagGroupId: number;
  tagGroupName: string;
  orderTags: OrderTags[];
  tags: Tag[];
  checked: boolean;
  quantity: number;
  guid: string;
}

export interface MenuActiveInfo {
  menuIdActive: number;
  categoryIdActive: number;
}

export interface WheelMenuCombos {
  price: any;
  isValid: boolean;
  errors: string[];
  selected: boolean;
  quantity: number;
  maxQuantity: number;
  id: number;
  description: string;
  dynamic: string;
  image: string;
  name: string;
  note?: string;
  published: boolean;
  screenCategoryId: number;
  screenMenuId: number;
  comboGroups: WheelComboGroup[];
  orderTags: OrderTags[];
  recommendedItems: IRecommendItem[];
  portions: Portions[];
  tags?: string[];
  states?: any;
  menuItemId: number;
  isSoldOut: boolean;
}

export interface WheelComboItem {
  type: number;
  note: any;
  autoSelect?: boolean;
  total: number;
  dTotal: number;
  maxQuantity: number;
  quantity: number;
  checked: boolean;
  id: number;
  price: any;
  description: string;
  dynamic: string;
  itemName: string;
  name: string;
  wheelMenuComboId: number;
  comboGroups: WheelComboGroup[];
  itemSelect?: CustomMenuItemData[];
  orderTags: OrderTags[];
  expanded?: boolean;
  minimum?: number;
  maximum?: number;
  isValid: boolean;
  errors: string[];
  menuItemId: number;
}

export interface WheelComboGroup {
  total: number;
  dTotal: number;
  isValid: boolean;
  selected: boolean;
  description: string;
  dynamic: string;
  itemSelect?: [];
  id: number;
  maximum: number;
  menuItemId: number;
  minimum: number;
  name: string;
  items: WheelComboItem[];
  expanded: boolean;
}

export interface IRecommendItem {
  recommendedItemId: number;
  recommendedItemName: string | undefined;
}

export interface categories {
  id: number;
  name: string;
  image: string;
  sortOrder: number;
}

export interface CurrentSession {
  name?: any;
  phoneNumber?: any;
  emailAddress?: any;
  sessionId: string;
  table: number;
  tableName: string;
  wheelLocation: number;
}
