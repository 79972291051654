import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IAppState } from '../../store';
import { ICustomerTopupInput, ITranscationPaymentInput } from '../model';
import { ApiResponse } from '../model/api-response.model';
import { HideSpinnerAction, ShowSpinnerAction } from '../store/actions/spinner.actions';

@Injectable({
  providedIn: 'root'
})
export class UserWalletService {

  url = environment.remoteServiceBaseUrl;

  constructor(
    private store: Store<IAppState>,
    private http: HttpClient,

  ) { }

  topUpRecharge(body: ICustomerTopupInput | undefined): Observable<ApiResponse> {
    const spinnerId = Guid.create().toString();
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));
    return this.http.post<ApiResponse>(`${this.url}/api/services/app/FrontWallet/ApiPaymentRequest`, body)
      .pipe(tap(() => this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))),
        catchError((err) => {
          this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
          return throwError(err);
        }));
  }

  validatePayment(body: { id: number, tenantId: number }) {

    let url_ = this.url + "/api/services/app/FrontWallet/ValidatePayment";
    url_ = url_.replace(/[?&]$/, "");
    return this.http.post<any>(url_, body);
  }

  walletBalance(userId: number) {
    return this.http.post(`${this.url}/api/services/app/FrontWallet/ApiWalletBalance`, {
      tenantId: environment.tenantId,
      userId: userId,
    }).pipe(
      map((resp: any) => {
        return resp.result;
      }));
  }

  transactionHistory(transcationPaymentInput: ITranscationPaymentInput) {
    return this.http.post(`${this.url}/api/services/app/FrontWallet/ApiTransactionHistory`, transcationPaymentInput).pipe(
      map((resp: any) => {
        return resp.result;
      }));

  }

  paymentRequests(userId: number) {
    return this.http.post(`${this.url}/api/services/app/FrontWallet/GetUserPaymentRequestReport`, {
      tenantId: environment.tenantId,
      userId: userId,
    }).pipe(
      map((resp: any) => {
        return resp.result;
      }));
  }
}
