import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { IAppState } from '../../../store';
import { UserService } from '../../service';
import { selectIsLoggedIn } from '../../store/selectors/auth.selector';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<IAppState>,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let url = state.url;

    return true;
    //return this.store
    //  .pipe(
    //    select(selectIsLoggedIn),
    //    tap(loggedIn => {
    //      if (!loggedIn) {
    //        this.router.navigate([`${environment.selectedTenantName}/signin`], { queryParams: { returnUrl: url } });
    //      }
    //    })
    //  );
  }
}
