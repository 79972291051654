import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LanguageGuard implements CanActivate {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const language = window.sessionStorage.getItem('LANGUAGE_DISPLAY');
    
    // if (!language) {
    //   this.router.navigateByUrl('language');
    //   return false;
    // }
    return true;
  }

}
