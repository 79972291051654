import { Action } from '@ngrx/store';

export enum SpinnerActionTypes {
  ShowSpinner = '[Spinner] Show Spinner',
  ShowSpinnerCompleted = '[Spinner] Show Spinner Completed',

  HideSpinner = '[Spinner] Hide Spinner',
  HideSpinnerCompleted = '[Spinner] Hide Spinner Completed',
}

export class ShowSpinnerAction implements Action {
  readonly type = SpinnerActionTypes.ShowSpinner;
  constructor(public payload?: { name?: string }) {}
}

export class HideSpinnerAction implements Action {
  readonly type = SpinnerActionTypes.HideSpinner;
  constructor(public payload?: { name?: string }) {}
}

export type SpinnerActions =
  | ShowSpinnerAction
  | HideSpinnerAction;
