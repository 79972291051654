import { IAddress } from './address.model';
import * as moment from 'moment';
export interface ILocation {
  name: string | undefined;
  code: string | undefined;
  phoneNumber: string | undefined;
  website: string | undefined;
  email: string | undefined;
  fax: string | undefined;
  organizationId: number;
  organizationName: string | undefined;
  address: IAddress;
  lastModificationTime: moment.Moment | undefined;
  lastModifierUserId: number | undefined;
  creationTime: moment.Moment;
  creatorUserId: number | undefined;
  id: number;
}
