export interface ITableOtpRequest {
  otp?: number;
  table: number;
  tenantId: number;
}

export interface ITableOtpResponse {
  error: string;
  result: IWheelTable;
  success: boolean;
}

export interface IWheelTable {
  tableName: string;
  status: boolean;
  tenantId: number;
  isMultiLocation: boolean;
}
