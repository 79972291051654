import { createFeatureSelector, createSelector } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { IWheelLocation } from '../../common';
import { ICartItem, ICartItems } from '../../model/cart.model';
import {
  IWheelSetupAddon,
  IWheelSetupModel,
  IWheelSetupResultModel,
} from '../../model/wheel-setup.model';
import { ICommonState, commonStateKey } from '../reducers/common.reducers';
import { IWheelTable } from '../../../core/model';

export const selectCommonState =
  createFeatureSelector<ICommonState>(commonStateKey);

export const selectCartItems = createSelector(
  selectCommonState,
  (commonState) => {
    try {
      var res = cloneDeep(commonState.cartItems) as ICartItems;
      if (res?.result) {
        return {
          result: {
            cartItems: res.result.cartItems.filter((f) => !f.stopChange),
            deliveryFees: res.result.deliveryFees,
            frontCartTotal: res.result.frontCartTotal,
          },
        } as ICartItems;
      }
    } catch (e) {
      console.log(e);
    }
    return cloneDeep(commonState.cartItems) as ICartItems;
  }
);

export const selectTable = createSelector(
  selectCommonState,
  (commonState) => cloneDeep(commonState?.tableDetail?.result) as IWheelTable
);

export const selectTableName = createSelector(
  selectCommonState,
  (commonState) =>
    cloneDeep(commonState?.tableDetail?.result?.tableName) as string
);

export const selectSetupData = createSelector(
  selectCommonState,
  (commonState) => cloneDeep(commonState?.setupData) as IWheelSetupResultModel
);

export const selectLocation = (locationId: number) =>
  createSelector(
    selectSetupData,
    (commonState) =>
      cloneDeep(
        commonState?.wheelLocations?.find((f) => f.id == locationId)
      ) as unknown as IWheelLocation
  );

export const selectAddon = createSelector(
  selectCommonState,
  (commonState) =>
    commonState?.setupData?.addOns &&
    (JSON.parse(cloneDeep(commonState?.setupData?.addOns)) as IWheelSetupAddon)
);

export const selectForceLogin = createSelector(
  selectAddon,
  (addOn) => addOn?.ForceLogin
);

export const selectSplashImage = createSelector(
  selectCommonState,
  (commonState) => commonState.gatewayData?.WelcomeUrl
);

export const selectAdyenSession = createSelector(
  selectCommonState,
  (commonState) => commonState.adyenSessionResult
);

export const selectAdyenPaymentMethods = createSelector(
  selectCommonState,
  (commonState) => commonState.adyenPaymentMethods
);

export const selectTimeOutDialogState = createSelector(
  selectCommonState,
  (commonState) => commonState.timeOutDialogSate
);
