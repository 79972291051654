export class HttpOptions {
  url: string = '';
  body?: any;
  cacheMins?: number;
  specificCacheKey?: string;
  forceReload?: boolean;
  typeToken?: TokenType;
}
export enum TokenType {
  META_TOKEN = 1,
  NORMAL_TOKEN = 2,
}
