import { ISimpleLocation } from './simple-location.model';
import { ILocationGroup } from '.';

export interface IGetMenuItemForEditOutput {
  menuItem: IMenuItemEdit;
  combos: any[] | undefined;
  productType: number;
  locationGroup: ILocationGroup;
}
export interface IMenuItemEdit {
  id: number | undefined;
  barCode: string | undefined;
  aliasCode: string | undefined;
  aliasName: string | undefined;
  itemDescription: string | undefined;
  forceQuantity: boolean;
  forceChangePrice: boolean;
  restrictPromotion: boolean;
  noTax: boolean;
  categoryId: number | undefined;
  categoryCode: string | undefined;
  categoryName: string | undefined;
  groupCode: string | undefined;
  groupName: string | undefined;
  tpName: string | undefined;
  tpPrice: number;
  transactionTypeId: number | undefined;
  transactionTypeName: string | undefined;
  name: string | undefined;
  productType: number | undefined;
  tag: string | undefined;
  files: string | undefined;
  downloadImage: string;
  hsnCode: string | undefined;
  locationGroup: ICommonLocationGroup;
  barcodes: IMenuBarCodeEdit[] | undefined;
  portions: IMenuItemPortionEdit[] | undefined;
  upMenuItems: IUpMenuItemEdit[] | undefined;
  upSingleMenuItems: IUpMenuItemEdit[] | undefined;
  menuItemSchedules: IMenuItemScheduleEdit[] | undefined;
  menuItemDescriptions: IMenuItemDescriptionEdit[] | undefined;
  organizationId: number;
  uom: string | undefined;
  rowIndex: number;
  foodType: number | undefined;
  addOns: string | undefined;
  locations: string | undefined;
  group: boolean;
  nonLocations: string | undefined;
  locationTag: boolean;
}
export interface IMenuItemPortionEdit {
  id: number | undefined;
  name: string | undefined;
  multiPlier: number;
  price: number;
  menuItemId: number;
}
export interface IMenuBarCodeEdit {
  id: number | undefined;
  barcode: string | undefined;
}

export interface IMenuItemDescriptionEdit {
  id: number | undefined;
  menuItemId: number;
  languageCode: string | undefined;
  productName: string | undefined;
  description: string | undefined;
}
export interface IMenuItemScheduleEdit {
  id: number | undefined;
  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;
  days: string | undefined;
  allDays: IComboboxItem[] | undefined;
}
export interface IComboboxItem {
  value: string | undefined;
  displayText: string | undefined;
  isSelected: boolean;
}
export interface IUpMenuItemEdit {
  id: number | undefined;
  menuItemId: number;
  name: string | undefined;
  refMenuItemId: number;
  addBaseProductPrice: boolean;
  price: number;
  productType: number;
  minimumQuantity: number;
  addAuto: boolean;
  addQuantity: number;
}
export interface ICommonLocationGroup {
  locations: ISimpleLocation[] | undefined;
  groups: IIdName[] | undefined;
  locationTags: IIdName[] | undefined;
  nonLocations: ISimpleLocation[] | undefined;
  group: boolean;
  locationTag: boolean;
  userId: number;
}
export interface IIdName {
  id: number;
  name: string | undefined;
}
