import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Loader } from '@googlemaps/js-api-loader';
import { IOrderCheckout, IValidatePromoCodeOutput } from '../model';
import { GOOGLE_MAP_KEY } from '../common/constant/shared.const';
import { environment } from '../../../environments/environment';
import { Guid } from 'guid-typescript';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store';
import {
  HideSpinnerAction,
  ShowSpinnerAction,
} from '../store/actions/spinner.actions';
import { catchError, tap } from 'rxjs/operators';
import { ApiResponse } from '../model/api-response.model';
import { CONSTANT } from '../common';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class CheckOutService {
  constructor(private client: HttpClient, private store: Store<IAppState>) {}

  chargedOrder(orderDetails: IOrderCheckout) {
    let url = '/api/services/app/OrderDetails/CreateOrEdit'.replace(
      /[?&]$/,
      ''
    );

    return this.client.post(url, orderDetails).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getPastOrder(): Observable<ApiResponse> {
    let url = '/api/services/app/OrderDetails/GetAll'.replace(/[?&]$/, '');

    return this.client.get<ApiResponse>(url).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  createPaymentForOrder(body: any): Observable<any> {
    let url = '/api/services/app/frontTicket/PaymentRequest'.replace(
      /[?&]$/,
      ''
    );
    return this.client.post<ApiResponse>(url, body).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getCCAvenueEncryptedData(body: any): Observable<any> {
    let url = '/api/services/app/frontTicket/GetCCAvenueEncryptedData'.replace(
      /[?&]$/,
      ''
    );

    return this.client.post<ApiResponse>(url, body).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  validatePayment(body: any): Observable<any> {
    let url = '/api/services/app/FrontTicket/ValidatePayment'.replace(
      /[?&]$/,
      ''
    );

    return this.client.post<ApiResponse>(url, body).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  reverseNetsQRTransaction(body: any): Observable<any> {
    let url =
      '/api/services/app/PaymentGateway/ApiReverseNetsQRTransaction'.replace(
        /[?&]$/,
        ''
      );

    return this.client.post<ApiResponse>(url, body).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  searchLocation(body: string): Observable<any> {
    let url_ =
      'https://maps.googleapis.com/maps/api/geocode/json?key=' + GOOGLE_MAP_KEY;
    if (body) {
      url_ += '&address=' + encodeURIComponent(body);
    }
    url_ = url_.replace(/[?&]$/, '');

    return this.client.get(url_);
  }

  checkSlotOrder(body: any): Observable<any> {
    let url =
      '/api/services/app/frontWheelDepartment/ApiCheckTimeSlotOrder'.replace(
        /[?&]$/,
        ''
      );

    return this.client.post<ApiResponse>(url, body).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  loadScript = async (): Promise<void> => {
    const loader = new Loader({
      apiKey: GOOGLE_MAP_KEY,
    });
    await loader.load();
  };

  async AddressToGeoCode(address: string, callback: (res: any) => void) {
    await this.loadScript();
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, (res) => {
      if (callback) {
        callback(res);
      }
    });
  }

  CheckDeliveryZone(body: any): Observable<any> {
    let url = '/api/services/app/WheelOrder/CheckDeliveryZone'.replace(
      /[?&]$/,
      ''
    );

    return this.client.post<ApiResponse>(url, body).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  checkMinOrderByDeliveryZone(body: any): Observable<any> {
    let url =
      '/api/services/app/WheelOrder/CheckMinOrderByDeliveryZone'.replace(
        /[?&]$/,
        ''
      );

    return this.client.post<ApiResponse>(url, body).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  payOrderWithHitPay(body: any): Observable<ApiResponse> {
    let url = '/api/services/app/WheelOrder/PlaceOrderWithHitPay'.replace(
      /[?&]$/,
      ''
    );

    return this.client.post<ApiResponse>(url, body).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getCustomerSessionData(key: string): Observable<ApiResponse> {
    let url =
      '/api/services/app/WheelCheckout/GetCustomerSessionData?key=' + key;
    url = url.replace(/[?&]$/, '');

    return this.client.get<ApiResponse>(url).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getCustomerCheckoutData(
    locationId: number,
    includeSubTotal?: boolean
  ): Observable<any> {
    let url = '/api/services/app/FrontCart/ApiCartItems'.replace(/[?&]$/, '');
    const body = {
      tenantId: environment.tenantId,
      includeSubTotal: includeSubTotal,
      wheelLocationId: locationId,
      sessionId: environment.sessionId,
    };
    return this.client.post<ApiResponse>(url, body).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  resetCheckout(): Observable<any> {
    let url = '/api/services/app/WheelCheckout/ResetCheckout'.replace(
      /[?&]$/,
      ''
    );

    return this.client.post<ApiResponse>(url, {}).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  initOrdering(body: any): Observable<any> {
    let url = '/api/services/app/WheelCheckout/initOrdering'.replace(
      /[?&]$/,
      ''
    );

    return this.client.post<ApiResponse>(url, body).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  sendOtp(): Observable<any> {
    let url = '/api/services/app/WheelTable/GetAll';

    return this.client.post<ApiResponse>(url, {}).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getAdyenPaymentMethods(body: any): Observable<any> {
    let url = '/api/services/app/PaymentGateway/GetAdyenPaymentMethods';

    return this.client.post<ApiResponse>(url, body).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getCheckoutSession(body: any): Observable<any> {
    let cBody = cloneDeep(body);
    cBody.paymentTypeId = cBody.wheelPaymentTypeId;

    let url = '/api/services/app/PaymentGateway/GetAdyenPaymentSession';

    return this.client.post<ApiResponse>(url, cBody).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  saveAdyenPaymentResult(body: any): Observable<any> {
    let url = '/api/services/app/frontTicket/AdyenResponseHandler';
    const spinnerId = Guid.create().toString();
    this.store.dispatch(new ShowSpinnerAction({ name: spinnerId }));

    return this.client.post<ApiResponse>(url, body).pipe(
      tap(() =>
        this.store.dispatch(new HideSpinnerAction({ name: spinnerId }))
      ),
      catchError((err) => {
        this.store.dispatch(new HideSpinnerAction({ name: spinnerId }));
        return throwError(err);
      })
    );
  }

  syncTicketService(body: any): Observable<any> {
    let url = '/api/services/app/FrontSync/ApiTicketSyncStatus';

    return this.client.post<ApiResponse>(url, body).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getTicketStatus(body: any): Observable<any> {
    let url = '/api/services/app/FrontSync/ApiTicketStatus';

    return this.client.post<ApiResponse>(url, body).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  validateVoucher(body: any): Observable<ApiResponse> {
    let url = '/api/services/app/FrontCart/ApiValidateVoucher';

    return this.client.post<ApiResponse>(url, body).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getIPay88PaymentMethods(body): Observable<ApiResponse> {
    let url = '/api/services/app/PaymentGateway/ApiGetIPay88PaymentMethods';

    return this.client.post<ApiResponse>(url, body).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}
