import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { BehaviorSubject } from 'rxjs';
import * as localForage from 'localforage';
import { CONSTANT } from '../constant/constant';
import { CustomMenuItemData } from '../../model/custom-menu-item.model';
import { ISimpleLocation, IUser } from '../../model';
import { ICartModel } from '../../model/cart.model';

const STORAGE_KEY = CONSTANT.STORAGE_KEY as string;
const DELIVERY_LOCALTION_KEY = CONSTANT.DELIVERY_LOCALTION_KEY as string;
const USERKEY = CONSTANT.USERKEY as string;
const ADDRESS_DETAIL = CONSTANT.ADDRESS_DETAIL as string;

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  $orderListLocalStorage = new BehaviorSubject<CustomMenuItemData[]>(
    this.getOrderItemsOnLocalStorage()
  );

  $deliveryLocation = new BehaviorSubject<ISimpleLocation>(
    this.getDeliveryLocation()
  );

  $orderListModelStorage = new BehaviorSubject<ICartModel[]>(
    this.getCartItem()
  );

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) { }

  storeOrderItemsOnLocalStorage(items: any): void {
    let currentList = this.storage.get(STORAGE_KEY) || [];
    currentList = [...items];
    // insert updated array to local storage
    this.storage.set(STORAGE_KEY, currentList);
    this.$orderListLocalStorage.next(items);
  }

  getOrderItemsOnLocalStorage() {
    const items = this.storage.get(STORAGE_KEY) || [];
    return items;
  }

  clearOrderList() {
    this.storage.remove(STORAGE_KEY);
    this.$orderListLocalStorage.next([]);
  }

  setCartItem(carts: ICartModel[]) {
    this.storage.set(CONSTANT.CART_ORDER, carts);
    this.$orderListModelStorage.next(carts);
  }

  removeAllKeys() {
    this.storage.clear();
  }

  getCartItem() {
    return this.storage.get(CONSTANT.CART_ORDER) || [];
  }

  removeCartItem() {
    this.storage.remove(CONSTANT.CART_ORDER);
  }

  saveDeliveryLocation(location: ISimpleLocation) {
    if (location) {
      this.storage.set(DELIVERY_LOCALTION_KEY, location);
      this.$deliveryLocation.next(location);
    }
  }

  getDeliveryLocation() {
    const items = this.storage.get(DELIVERY_LOCALTION_KEY);
    return items;
  }

  saveUser(user: IUser) {
    if (user) {
      this.storage.set(USERKEY, user);
    }
  }

  getUser() {
    const items = this.storage.get(USERKEY);
    return items;
  }

  removeByKey(key: string) {
    this.storage.remove(key);
  }

  getByKey(key: string) {
    return this.storage.get(key);
  }

  saveAddress(addressDetail: string) {
    if (addressDetail) {
      this.storage.set(ADDRESS_DETAIL, addressDetail);
    }
  }

  getAddress() {
    const items = this.storage.get(ADDRESS_DETAIL);
    return items;
  }

  getItem(key: string, callback: any): void {
    if (!localForage) {
      return;
    }

    localForage.getItem(key, callback);
  }

  setItem(key: string, value: string, callback?: any): void {
    if (!localForage) {
      return;
    }

    if (value === null) {
      value = '';
    }

    localForage.setItem(key, value, callback);
  }

  removeItem(key: string, callback?: any): void {
    if (!localForage) {
      return;
    }

    localForage.removeItem(key, callback);
  }

}
