import { createAction, props } from "@ngrx/store"
import { ILogingResponseModel, ILoginRequestModel, ISignupRequestModel, ISignupResponseModel, IUser } from "../../model";

export const LoginActionRequested = createAction('[Dine Auth] Login Requested', props<{ body: ILoginRequestModel }>());
export const LoggedInAction = createAction('[Dine Auth] LoggedIn', props<{ user: ILogingResponseModel }>());
export const SignupActionRequested = createAction('[Dine Auth] Signup Requested', props<{ body: ISignupRequestModel }>());
export const SignupActionResponse = createAction('[Dine Auth] Signup response', props<{ user: ISignupResponseModel }>());


export const logoutAction = createAction('[Dine Auth] Logout');
export const UserLoaded = createAction('[Dine Auth] User Loaded', props<{ user: any }>());
export const refreshUser = createAction('[Dine Auth] Refresh User');
export const updateUser = createAction('[Dine Auth] Update User', props<{ user: IUser }>());
