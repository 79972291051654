import { Action, createReducer, on } from '@ngrx/store';
import {
  IAdyenPaymentMethods,
  IAdyenSessionResult,
  IIsTenantAvailableOutput,
  ITableOtpResponse,
  IWheelScreenItem,
} from '../../model';
import { ICartItems } from '../../model/cart.model';
import { IGatewayData } from '../../model/gateway.model';
import { IWheelSetupResultModel } from '../../model/wheel-setup.model';
import {
  adyenCheckoutSessionLoaded,
  adyenPaymentMethodsLoaded,
  cartItemsLoaded,
  checkTenantLoaded,
  resetCartRequested,
  tableClearData,
  tableDetailLoaded,
  timeOutDialogSate,
  wheelGatewayDataLoaded,
  wheelSetupLoaded,
} from '../actions/common.actions';

export interface ICommonState {
  tenantAvailable: IIsTenantAvailableOutput;
  cartItems: ICartItems;
  tableDetail: ITableOtpResponse;
  popupItem: IWheelScreenItem;
  setupData: IWheelSetupResultModel;
  gatewayData: IGatewayData;
  adyenSessionResult: IAdyenSessionResult;
  adyenPaymentMethods: IAdyenPaymentMethods[];
  timeOutDialogSate: boolean;
}

export const defaultState: ICommonState = {
  tenantAvailable: undefined,
  cartItems: undefined,
  tableDetail: undefined,
  popupItem: undefined,
  setupData: undefined,
  gatewayData: undefined,
  adyenSessionResult: undefined,
  adyenPaymentMethods: undefined,
  timeOutDialogSate: false,
};

const reducer = createReducer(
  defaultState,

  on(checkTenantLoaded, (state, action) => ({
    ...state,
    isTenantAvailable: action.result,
  })),
  on(resetCartRequested, (state, action) => ({
    ...state,
    cartItems: undefined,
  })),
  on(cartItemsLoaded, (state, action) => ({
    ...state,
    cartItems: action.result,
  })),

  on(tableDetailLoaded, (state, action) => ({
    ...state,
    tableDetail: action.result,
  })),
  on(tableClearData, (state, action) => ({
    ...state,
    tableDetail: undefined,
  })),

  on(wheelSetupLoaded, (state, action) => ({
    ...state,
    setupData: action.result,
  })),

  on(wheelGatewayDataLoaded, (state, action) => ({
    ...state,
    gatewayData: action.result,
  })),
  on(adyenCheckoutSessionLoaded, (state, action) => ({
    ...state,
    adyenSessionResult: action.result,
  })),
  on(adyenPaymentMethodsLoaded, (state, action) => ({
    ...state,
    adyenPaymentMethods: action.result,
  })),
  on(timeOutDialogSate, (state, action) => ({
    ...state,
    timeOutDialogSate: action.timeoutState,
  }))
);

export function commonReducer(
  state: ICommonState = defaultState,
  action: Action
): ICommonState {
  return reducer(state, action);
}

export const commonStateKey = 'commonState';
