import { Injectable } from '@angular/core';
import { withLatestFrom, map } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import * as fromActions from '../actions/spinner.actions';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { selectShownSpinnerCount } from '../selectors/spinner.selectors';

@Injectable()
export class SpinnerEffects {
  @Effect({ dispatch: false })
  showSpinner$ = this.actions$.pipe(
    ofType<fromActions.ShowSpinnerAction>(fromActions.SpinnerActionTypes.ShowSpinner),
    withLatestFrom(this.store.select(selectShownSpinnerCount)),
    map(([{ payload }, count]) => {
      this.spinner.show();
    })
  );

  @Effect({ dispatch: false })
  hideSpinner$ = this.actions$.pipe(
    ofType<fromActions.HideSpinnerAction>(fromActions.SpinnerActionTypes.HideSpinner),
    withLatestFrom(this.store.select(selectShownSpinnerCount)),
    map(([{ payload }, count]) => {
      if (count <= 0) {
        this.spinner.hide();
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private spinner: NgxSpinnerService,
  ) {}
}
