import { Injectable } from '@angular/core';
import { switchMap, map, catchError, filter, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  addItemToCartRequested,
  adyenCheckoutSessionLoaded,
  adyenCheckoutSessionRequested,
  adyenPaymentMethodsLoaded,
  adyenPaymentMethodsRequested,
  apiResponseError,
  cartItemsLoaded,
  cartItemsRequested,
  removeItemFromCart,
  removeSoloutItemsFromCart,
  tableDetailLoaded,
  tableDetailRequested,
  updateItemFromCart,
} from '../actions/common.actions';
import { WheelOrderService } from '../../service/wheel-order.service';
import { ICartItemActionModel, ITableOtpRequest } from '../../model';
import {
  CheckOutService,
  DineMessageService,
  WheelLocationsService,
} from '../../service';

@Injectable()
export class CommonEffect {
  @Effect()
  addItemToCart$ = this.actions$.pipe(
    ofType(addItemToCartRequested),
    map((action) => action.body),
    mergeMap((payload: { item: any; cartModel: any }) => {
      return this.orderService.addOrderItemToCart(payload).pipe(
        map((resp: any) => {
          //if (resp.success) {
          //  return cartItemsRequested(
          //    {
          //      body: payload.cartModel
          //    })
          //}
          return apiResponseError(resp.result.error.message);
        }),
        catchError((error) => {
          this.messageService.showError(error.message);
          return of(apiResponseError(error));
        })
      );
    })
  );

  @Effect()
  loadCatItems$ = this.actions$.pipe(
    ofType(cartItemsRequested),
    map((action) => action.body),
    filter((f) => Boolean(f.locationId)),
    mergeMap(
      (payload: {
        locationId: number;
        departmentId: number;
        sessionId: string;
        includeSubTotal: boolean;
        selectedAddress: any;
        voucherCode: any;
        includeStopChange: boolean;
      }) => {
        return this.orderService
          .getCart(
            payload.locationId,
            payload.departmentId,
            payload.sessionId,
            payload.includeSubTotal,
            payload.selectedAddress,
            payload.voucherCode,
            payload.includeStopChange
          )
          .pipe(
            map((resp: any) =>
              cartItemsLoaded({
                result: resp,
              })
            ),
            catchError((error) => {
              this.messageService.showError(error.message);
              return of(apiResponseError(error));
            })
          );
      }
    )
  );

  @Effect()
  removeItemFromCart$ = this.actions$.pipe(
    ofType(removeItemFromCart),
    map((action) => action.body),
    switchMap((payload: ICartItemActionModel) => {
      return this.orderService.removeOrderItemFromCart(payload).pipe(
        map((resp: any) => {
          if (resp.success) {
            return cartItemsRequested({
              body: {
                departmentId: payload.departmentId,
                locationId: payload.locationId,
                sessionId: payload.sessionId,
                includeSubTotal: true,
                selectedAddress: payload.selectedAddress,
              },
            });
          }
          return apiResponseError(resp.result.error.message);
        }),
        catchError((error) => {
          this.messageService.showError(error.message);
          return of(apiResponseError(error));
        })
      );
    })
  );

  @Effect()
  removeSoldItemsFromCart$ = this.actions$.pipe(
    ofType(removeSoloutItemsFromCart),
    map((action) => action.body),
    switchMap((payload: ICartItemActionModel) => {
      return this.orderService.removeSoldItemsFromCart(payload).pipe(
        map((resp: any) => {
          if (resp.success) {
            return cartItemsRequested({
              body: {
                departmentId: payload.departmentId,
                locationId: payload.locationId,
                sessionId: payload.sessionId,
                includeSubTotal: true,
                selectedAddress: payload.selectedAddress,
              },
            });
          }
          return apiResponseError(resp.result.error.message);
        }),
        catchError((error) => {
          this.messageService.showError(error.message);
          return of(apiResponseError(error));
        })
      );
    })
  );

  @Effect()
  updateItemFromCart$ = this.actions$.pipe(
    ofType(updateItemFromCart),
    map((action) => action.body),
    switchMap((payload: ICartItemActionModel) => {
      return this.orderService.updateOrderItemToCart(payload).pipe(
        map((resp: any) => {
          if (resp.success) {
            return cartItemsRequested({
              body: {
                departmentId: payload.departmentId,
                locationId: payload.locationId,
                sessionId: payload.sessionId,
                includeSubTotal: true,
                selectedAddress: payload.selectedAddress,
              },
            });
          }
          return apiResponseError(resp.result.error.message);
        }),
        catchError((error) => {
          this.messageService.showError(error.message);
          return of(apiResponseError(error));
        })
      );
    })
  );

  @Effect()
  loadTableDetail$ = this.actions$.pipe(
    ofType(tableDetailRequested),
    map((action) => action.body),
    switchMap((payload: ITableOtpRequest) => {
      return this.locationService
        .getTableDetail(payload.tenantId, payload.table)
        .pipe(
          map((resp: any) =>
            tableDetailLoaded({
              result: resp,
            })
          ),
          catchError((error) => {
            this.messageService.showError(error.message);
            return of(apiResponseError(error));
          })
        );
    })
  );

  @Effect()
  loadAdyenCheckoutSession$ = this.actions$.pipe(
    ofType(adyenCheckoutSessionRequested),
    map((action) => action.body),
    switchMap((payload: any) => {
      return this.checkoutService.getCheckoutSession(payload).pipe(
        map((resp: any) =>
          adyenCheckoutSessionLoaded({
            result: resp.result,
          })
        ),
        catchError((error) => {
          this.messageService.showError(error.message);
          return of(apiResponseError(error));
        })
      );
    })
  );

  @Effect()
  loadAdyenAdyenPaymentMethods$ = this.actions$.pipe(
    ofType(adyenPaymentMethodsRequested),
    map((action) => action.body),
    switchMap((payload: any) => {
      return this.checkoutService.getAdyenPaymentMethods(payload).pipe(
        map((resp: any) =>
          adyenPaymentMethodsLoaded({
            result: resp.result.paymentMethods,
          })
        ),
        catchError((error) => {
          this.messageService.showError(error.message);
          return of(apiResponseError(error));
        })
      );
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private orderService: WheelOrderService,
    private messageService: DineMessageService,
    private locationService: WheelLocationsService,
    private checkoutService: CheckOutService
  ) {}
}
