export interface ICouponResponse {
  originalAmount: number;
  totalClaimAmount: number;
  discountedAmount: number;
  discountDetails: {
    id: number;
    code: string;
    claimAmount: number;
  }[];
}
