export interface IIsTenantAvailableOutput {
  state: TenantAvailabilityState;
  tenantId: number | undefined;
  serverRootAddress: string | undefined;
}
export enum TenantAvailabilityState {
  Available = 1,
  InActive = 2,
  NotFound = 3,
}
export interface IIsTenantAvailableInput {
  tenancyName: string | undefined;
}
