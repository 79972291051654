import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { LocalStorageService, HttpOptions, TokenType } from '../../core/common';
import { HttpClientService } from '../../core/common/config/http-client/http-client.service';
import * as _ from 'lodash';
import { WheelOrderService } from './wheel-order.service';
import {
  ICategory,
  IGetMenuItemForEditOutput,
  IPageResult,
  IPageResultList,
  MenuItemData,
  WheelMenuCombos,
} from '../model';
import { CustomMenuItemData, IMenuItem } from '../model/custom-menu-item.model';
import { IOrderItem } from '../model/cart.model';
import { environment } from '../../../environments/environment';
import { wheelDataSetups } from '../common/constant/constant';
import { first } from 'lodash';
import { IWheelInvoiceResponseModel } from '../model/order.model';
import { ApiResponse } from '../model/api-response.model';

@Injectable({
  providedIn: 'root',
})
export class MenuItemService {
  $menuItemSubject = new Subject<MenuItemData[]>();
  $firstSectionSubject = new Subject<MenuItemData[]>();
  $commonMenuIDSubject = new Subject();
  $menuIDSubject = new Subject();
  $closeLeftMenuSubject = new Subject<boolean>();
  orderList: CustomMenuItemData[] = [];
  constructor(private http: HttpClientService) {}

  getAllCategory(
    filter?: string | null,
    sorting?: string | null,
    maxResultCount?: number | undefined,
    skipCount?: number | undefined
  ): Observable<IPageResultList<ICategory>> {
    let url_ = '/api/services/app/Category/GetAll?';
    if (filter !== undefined) {
      url_ += 'Filter=' + encodeURIComponent('' + filter) + '&';
    }
    if (sorting !== undefined) {
      url_ += 'Sorting=' + encodeURIComponent('' + sorting) + '&';
    }
    if (maxResultCount === null) {
      throw new Error("The parameter 'maxResultCount' cannot be null.");
    } else if (maxResultCount !== undefined) {
      url_ += 'MaxResultCount=' + encodeURIComponent('' + maxResultCount) + '&';
    }
    if (skipCount === null) {
      throw new Error("The parameter 'skipCount' cannot be null.");
    } else if (skipCount !== undefined) {
      url_ += 'SkipCount=' + encodeURIComponent('' + skipCount) + '&';
    }
    url_ = url_.replace(/[?&]$/, '');

    const options: HttpOptions = {
      url: url_,
      cacheMins: 5,
      typeToken: TokenType.META_TOKEN,
    };
    return this.http.get<IPageResultList<ICategory>>(options);
  }

  getAllMenuItems(
    filter?: string | null,
    sorting?: string | null,
    maxResultCount?: number | undefined,
    skipCount?: number | undefined
  ): Observable<IPageResultList<IMenuItem>> {
    let url_ = '/api/services/app/MenuItem/GetAll?';
    if (filter !== undefined) {
      url_ += 'Filter=' + encodeURIComponent('' + filter) + '&';
    }
    if (sorting !== undefined) {
      url_ += 'Sorting=' + encodeURIComponent('' + sorting) + '&';
    }
    if (maxResultCount === null) {
      throw new Error("The parameter 'maxResultCount' cannot be null.");
    } else if (maxResultCount !== undefined) {
      url_ += 'MaxResultCount=' + encodeURIComponent('' + maxResultCount) + '&';
    }
    if (skipCount === null) {
      throw new Error("The parameter 'skipCount' cannot be null.");
    } else if (skipCount !== undefined) {
      url_ += 'SkipCount=' + encodeURIComponent('' + skipCount) + '&';
    }
    url_ = url_.replace(/[?&]$/, '');

    const options: HttpOptions = {
      url: url_,
      cacheMins: 5,
      typeToken: TokenType.META_TOKEN,
    };
    return this.http.get<IPageResultList<IMenuItem>>(options);
  }

  getCatagories(id: number, tenantId: number): Observable<IPageResult<any>> {
    let urlString = '/api/services/app/frontMenu/ApiScreenCategories';
    if (!id || !tenantId) {
      throw new Error("The parameter 'id' or 'tenantId' cannot be null.");
    }
    urlString = urlString.replace(/[?&]$/, '');

    const options: HttpOptions = {
      url: urlString,
      body: { id: id, tenantId: tenantId },
      cacheMins: 5,
      typeToken: TokenType.META_TOKEN,
    };
    return this.http.post<IPageResult<any>>(options);
  }

  getMenuItemChildren(
    filter: string | null | undefined,
    id: number | undefined
  ): Observable<IPageResult<MenuItemData[]>> {
    let urlString = '/api/services/app/WheelScreenMenuItems/GetChildren?';
    if (filter !== undefined && filter !== null) {
      urlString += 'Filter=' + encodeURIComponent('' + filter) + '&';
    }
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      urlString += 'Id=' + encodeURIComponent('' + id) + '&';
    }
    urlString = urlString.replace(/[?&]$/, '');
    const options: HttpOptions = {
      url: urlString,
      cacheMins: 5,
      typeToken: TokenType.META_TOKEN,
    };

    return this.http.get<IPageResult<MenuItemData[]>>(options);
  }

  getMenuItemForEdit(
    id: number | null | undefined
  ): Observable<IPageResult<IGetMenuItemForEditOutput>> {
    let url_ = '/api/services/app/MenuItem/GetMenuItemForEdit?';
    if (id !== undefined) {
      url_ += 'Id=' + encodeURIComponent('' + id) + '&';
    }
    url_ = url_.replace(/[?&]$/, '');
    const options: HttpOptions = {
      url: url_,
      cacheMins: 3,
      typeToken: TokenType.META_TOKEN,
    };
    return this.http.get(options);
  }

  getWheelScreenMenuForEdit(
    id: number | undefined
  ): Observable<IPageResultList<any>> {
    let url_ = '/api/services/app/WheelScreenMenus/GetWheelScreenMenuForEdit?';
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += 'Id=' + encodeURIComponent('' + id) + '&';
    }
    url_ = url_.replace(/[?&]$/, '');

    const options: HttpOptions = {
      url: url_,
      cacheMins: 3,
      typeToken: TokenType.META_TOKEN,
    };
    return this.http.get<IPageResultList<any>>(options);
  }

  getWheelTicketsBySession(locationId: number): Observable<ApiResponse> {
    let url_ = '/api/services/app/frontSync/ApiGetTicketsBySession';

    const options: HttpOptions = {
      url: url_,
      body: {
        tenantId: environment.tenantId,
        sessionId: environment.sessionId,
        locationId: locationId,
      },
    };

    return this.http.post<ApiResponse>(options);
  }

  getWheelOrderHistory(): Observable<IWheelInvoiceResponseModel> {
    let url_ = '/api/services/app/frontSync/ApiGetTicketByInvoice';
    const locationId: number = first(wheelDataSetups.wheelLocations as any[])
      ? first(wheelDataSetups.wheelLocations as any[]).locationId
      : 0;

    const options: HttpOptions = {
      url: url_,
      cacheMins: 1,
      body: {
        tenantId: environment.tenantId,
        sessionId: environment.sessionId,
        locationId: locationId,
      },
      typeToken: TokenType.META_TOKEN,
    };

    return this.http.post<IPageResult<any>>(options);
  }

  getSubCategories(
    menuId: number,
    locationId: number,
    tenantId: number
  ): Observable<IPageResult<any>> {
    let url_ = '/api/services/app/frontMenu/ApiSubCategories';

    url_ = url_.replace(/[?&]$/, '');
    const options: HttpOptions = {
      url: url_,
      body: { id: menuId, tenantId: tenantId, locationId: locationId },
      typeToken: TokenType.META_TOKEN,
    };

    return this.http.post<IPageResult<any>>(options);
  }

  getMenuItemsData(
    menuId: number,
    tenantId: number,
    locationId: number,
    Search: string,
    SkipCount: number = 0,
    MaxResultCount: number = 100,
    SubCategory: string = ''
  ): Observable<IPageResult<any>> {
    let url_ = '/api/services/app/frontMenu/ApiScreenItems';

    url_ = url_.replace(/[?&]$/, '');
    const options: HttpOptions = {
      url: url_,
      body: {
        id: menuId,
        tenantId: tenantId,
        locationId: locationId,
        Search: Search,
        SkipCount: SkipCount,
        MaxResultCount: MaxResultCount,
        SubCategory: SubCategory,
      },
      typeToken: TokenType.META_TOKEN,
    };

    return this.http.post<IPageResult<any>>(options);
  }

  getIndividualItemData(
    menuId: number,
    location: number,
    tenantId: number
  ): Observable<IPageResult<any>> {
    let url_ = '/api/services/app/frontMenu/ApiScreenItemDetail';
    const options: HttpOptions = {
      url: url_,
      body: { id: menuId, tenantId: tenantId, locationId: location },
      typeToken: TokenType.META_TOKEN,
    };
    return this.http.post<IPageResult<any>>(options);
  }

  generateKey(item: CustomMenuItemData): string {
    const edges = ['menuId-' + item.id, 'size-' + item.portions.id];
    const tagIds = item.tags.map((tag) => {
      return tag.id;
    });
    const keys = [...edges, tagIds].sort();
    return keys.join('-');
  }

  // totalItem is null => calculate the price per item
  // totalItem have value => calculate the price by total items
  calculatePrice(
    basePrice: number,
    orderItems: IOrderItem[],
    totalItems?: number
  ): number {
    let addOnPrice = 0;
    orderItems.forEach((e) => {
      addOnPrice += e.totalPrice;
    });
    return (basePrice + addOnPrice) * (totalItems ?? 1);
  }

  generateComboKey(item: WheelMenuCombos): string {
    const edges = [
      'menuId-' + item.screenMenuId,
      'categoryId-' + item.screenCategoryId,
      'comboId-' + item.id,
    ];
    let orderItem: any[] = [];
    item.comboGroups.map((e: any) => {
      orderItem = [...orderItem, ...e.itemSelect];
    });
    let keys = orderItem.map((e) => 'orderItem-' + e.id);

    for (const iterator of orderItem) {
      const tagIds = iterator.orderTags.map((orderTag: any) => {
        const tagChecked = orderTag.tags
          .filter((x: any) => x.checked)
          .map((e: any) => e.id + e.name);
        return tagChecked;
      });
      const mergedTagIds = Array.prototype.concat.apply([], tagIds);
      if (mergedTagIds.length > 0) {
        keys = [...keys, ...mergedTagIds];
      }
    }

    const finalKeys = [...edges, ...keys].sort();
    return finalKeys.join('-');
  }

  generateComboItemKey(comboId: number, item: CustomMenuItemData): string {
    const edges = ['combo-' + comboId, 'menuId-' + item.id];
    const tagIds = item.tags.map((tag) => {
      return tag.id;
    });
    const keys = [...edges, tagIds].sort();
    return keys.join('-');
  }
}
