import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BaseComponent } from '../../core/components/base.component';
import { IAppState } from '../../store';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss'],
  animations: [
    trigger(
      'modalFadeZoom',
      [
        transition(
          ':enter', [
          style({ transform: 'scale(2.0)', opacity: 0 }),
          animate('2.3s', style({ opacity: 1, transform: 'scale(1.0)' })),
        ]
        ),
        transition(
          ':leave', [
          style({ opacity: 1, transform: 'scale(1)' }),
          animate('14.3s', style({ opacity: 0, transform: 'scale(1.7)' })),
        ]
        ),
      ])
  ]
})
export class PreloaderComponent extends BaseComponent implements OnInit {
  splashUrl = environment.splashImageUrl;
  animationImage = environment.animationImage;
  constructor(
    private cdr: ChangeDetectorRef,
    store: Store<IAppState>
  ) {
    super(store);
    this.cdr.markForCheck();
  }

  ngOnInit(): void {
  }
}
