import { LocalStorageService } from '../local-storage/local-storage.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientService } from '../config/http-client/http-client.service';
import { IPageResult } from '../../model';
import { HttpOptions } from '..';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  constructor(
    private http: HttpClientService,
    private localStorageService: LocalStorageService
  ) {}

  authenticate(
    body: any | undefined,
    metadataAuthenticate = '',
    forceReload = false
  ): Observable<IPageResult<any>> {
    let url_ = `/api/TokenAuth/AuthenticatePortal`;
    url_ = url_.replace(/[?&]$/, '');

    const postContent: HttpOptions = {
      url: url_,
      body: body,
      cacheMins: 10,
      specificCacheKey: metadataAuthenticate,
      forceReload: forceReload,
    };

    return this.http.post<IPageResult<any>>(postContent);
  }

  externalAuthenticate(
    body: IExternalAuthenticateModel | undefined,
    metadataAuthenticate = '',
    forceReload = false
  ): Observable<IExternalAuthenticateResultModel> {
    let url_ = `/api/TokenAuth/ExternalAuthenticateMember`;
    url_ = url_.replace(/[?&]$/, '');

    const postContent: HttpOptions = {
      url: url_,
      body: body,
      cacheMins: 10,
      specificCacheKey: metadataAuthenticate,
      forceReload: forceReload,
    };

    return this.http.post<IExternalAuthenticateResultModel>(postContent);
  }

  public getUserToken(): string {
    return '';
  }

  logOut(): Observable<void> {
    let url_ = '/api/TokenAuth/LogOut';
    url_ = url_.replace(/[?&]$/, '');

    const content: HttpOptions = {
      url: url_,
    };

    return this.http.get(content);
  }

  //refreshToken(metadataAuthenticate: string = ''): any {
  //  const body = {
  //    userNameOrEmailAddress: environment.serviceUserAccount,
  //    password: environment.servicePasswordAccount,
  //    rememberClient: false,
  //    returnUrl: null,
  //    singleSignIn: false,
  //    twoFactorRememberClientToken: null,
  //  };
  //  return this.authenticate(body, metadataAuthenticate);
  //}
}


export interface IExternalAuthenticateModel {
  authProvider: string;
  providerKey: string;
  providerAccessCode: string;
  returnUrl: string | undefined;
  singleSignIn: boolean | undefined;
}

export interface IExternalAuthenticateResultModel {
  accessToken: string | undefined;
  encryptedAccessToken: string | undefined;
  expireInSeconds: number;
  waitingForActivation: boolean;
  returnUrl: string | undefined;
  refreshToken: string | undefined;
  refreshTokenExpireInSeconds: number;
}
