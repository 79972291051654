import { Action, createReducer, on } from '@ngrx/store';
import { IWheelScreenCategory, IWheelScreenItem, IWheelScreenItemsRequest } from '../../model';
import { ICartItem } from '../../model/cart.model';
import { closeAddItemToCartPopup, openAddItemToCartPopup, toggleMenuViewType, wheelCategoriesLoaded, wheelCategoriesRequested, wheelScreenMenuItemsLoaded, wheelScreenMenuItemsRequested, wheelScreenMenuItemsSearchLoaded, wheelScreenMenuItemsSearchRequested, wheelSubCategoriesLoaded, wheelSubCategoriesRequested } from '../actions/menu.actions';


export interface IMenuState {
  categories: Array<IWheelScreenCategory>;
  subCategories: {
    [category: string]: {
      loading: boolean;
      completed: boolean;
      error: boolean;
      request: string,
      data: string[];
    }
  },
  menuItems: {
    [pageId: string]: {
      loading: boolean;
      completed: boolean;
      error: boolean;
      request: IWheelScreenItemsRequest,
      data: IWheelScreenItem[];
    }
  },
  lastMenuItemRequest: IWheelScreenItemsRequest;
  popupItem: {
    item: IWheelScreenItem,
    cartItem: ICartItem
  },
  isGridView: boolean;
}

export const defaultState: IMenuState = {
  categories: undefined,
  subCategories: {},
  menuItems: {},
  lastMenuItemRequest: undefined,
  popupItem: undefined,
  isGridView: false
};

const reducer = createReducer(
  defaultState,

  on(wheelCategoriesLoaded,
    (state, action) => ({
      ...state,
      categories: action.result.result.categories,
    }),
  ),

  on(wheelSubCategoriesRequested,
    (state, action) => ({
      ...state,
      subCategories: {
        ...state.subCategories,
        [action.body.id]: {
          ...state.subCategories[action.body.id],
          loading: true,
        }
      }
    }),
  ),
    on(wheelSubCategoriesLoaded,
    (state, action) => ({
      ...state,
      subCategories: {
        ...state.subCategories,
        [action.catId]: {
          loading: false,
          error: false,
          completed: true,
          data: action.result.result.subCategories,
        }
      }
    }),
  ),

  on(wheelScreenMenuItemsRequested,
    (state, action) => ({
      ...state,
      menuItems: {
        ...state.menuItems,
        [action.body.pageNo]: {
          ...state.menuItems[action.body.pageNo],
          loading: true,
        }
      },
      lastMenuItemRequest: action.body
    }),
  ),
  on(wheelScreenMenuItemsLoaded,
    (state, action) => ({
      ...state,
      menuItems: {
        ...state.menuItems,
        [action.pageNo]: {
          loading: false,
          error: false,
          completed: true,
          data: action.result.result.menuItems,
        },
      }
    }),
  ),

  on(wheelScreenMenuItemsSearchRequested,
    (state, action) => ({
      ...state,
      menuItems: {
        ...state.menuItems,
      }
    }),
  ),
  on(wheelScreenMenuItemsSearchLoaded,
    (state, action) => ({
      ...state,
      menuItems: {
        ...state.menuItems,
      }
    }),
  ),

  on(openAddItemToCartPopup,
    (state, action) => ({
      ...state,
      popupItem: {item: action.item, cartItem: action.cartItem},
    }),
  ),
  on(closeAddItemToCartPopup,
    (state) => ({
      ...state,
      popupItem: undefined,
    }),
  ),
  on(toggleMenuViewType,
    (state, action) => ({
      ...state,
      isGridView: action.isGridView,
    }),
  )

)

export function menuReducer(state: IMenuState = defaultState, action: Action): IMenuState {
  return reducer(state, action);
}

export const menuStateKey = 'menuState';
