import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Observable, throwError } from "rxjs";
import { catchError, take } from "rxjs/operators";
import { ToastrService } from 'ngx-toastr';
import { environment } from "../../../environments/environment.prod";
import { ApiResponse } from "../model/api-response.model";
import { Router } from "@angular/router";
import { CONSTANT } from "../common";


@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private email = 'edineuser@gmail.com';
  private password = 'Qwerty@123';

  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private firebaseDb: AngularFireDatabase,
    private toastr: ToastrService,
    private http: HttpClient) {

  }

  signOut() {
    this.afAuth.signOut();
  }

  async signInToFb() {
    await this.firebaseDb.database.goOnline();
    const auth = getAuth();
    signInWithEmailAndPassword(auth, this.email, this.password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(error);
      });

  }

  signInToFirebase() {
    this.firebaseDb.database.goOnline();

    this.getFbToken().subscribe((res) => {
      const userCredential = this.afAuth.signInWithCustomToken(
        res.result
      );
    });
  }

  private getFbToken(): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`/api/services/app/Firebase/LoginWithFbAsync`, { tenantId: environment.tenantId })
      .pipe(catchError((err) => {
        return throwError(err);
      }));
  }

  private disconnect() {
    this.firebaseDb.database.goOffline();
  }

  initializeNotification() {

    const channel = `/notifications`;
    let isExistingDataLoaded = true;

    const firebaseRef = this.firebaseDb.database.ref(channel);

    firebaseRef.off();
    firebaseRef.limitToLast(1).on('child_added', (x) => {
      let resp = x.val() as { notification_id: string, tenant_id: number; payment_request_id: number; invoice_id: string };
      const pendingOrder = sessionStorage.getItem(CONSTANT.PENDING_ORDER);

      if (resp.tenant_id === environment.tenantId && pendingOrder && Number(pendingOrder) === resp.payment_request_id) {
        sessionStorage.removeItem(CONSTANT.PENDING_ORDER);

        this.toastr.success('Order is created successfully. Tap message to view invoice detail', 'Pending Order Creation!', {
          timeOut: 6000,
        }).onTap
          .pipe(take(1))
          .subscribe(() => {
            this.router.navigateByUrl(
              `${environment.selectedTenantName}/order/payment-information/` + resp.invoice_id
            );
          });
      }

      var query = firebaseRef.orderByKey();
      query.get()
        .then((snapshot) => {
          snapshot.forEach((childSnapshot) => {
            var pkey = childSnapshot.key;
            firebaseRef.child("notifications/" + pkey).remove();
          });
        });
    });

  }
}

